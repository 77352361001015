import React from 'react'

import Faq from '../Faq'
import Modal from '../Modal'
import { H1, Preamble } from '../Type'

import styles from './styles.module.css'

export default ({
    ...rest
}) => (
    <Modal {...rest}>
        <div className={styles.container}>
            <H1>
                Tack för<br />
                beställningen!
            </H1>
            <Preamble>Din städhjälp är på väg.</Preamble>
            <img
                className={styles.image}
                src="/purei9.jpg"
                alt="Electrolux Pure i9"
            />
        </div>
        <Faq />
    </Modal>
)
