import React from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

export default ({
    setNode,
    playing,
    inView,
    align,
    update,

    onRef,

    ...rest
}) => (
    <div
        ref={onRef}
        className={classnames(styles.root, styles[align])}
    >
        <video
            ref={setNode}
            className={styles.video}
            playsInline
            muted
            {...rest}
        />
    </div>
)