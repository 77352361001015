import React from 'react'

import Svg from '../Svg'

import styles from './styles.module.css'

export default ({
    light,

    ...rest
}) => (
    <div className={styles.root} {...rest}>
        <Svg
            viewBox="0 0 137 32"
            className={styles.logo}
            fill={light ? 'white' : '#011E41'}
        >
            <path d="M0 31.2118H31.2118V0H0V31.2118ZM20.0137 2.38258C25.5532 4.16951 29.544 9.41119 29.544 15.6059C29.544 21.6815 25.6723 26.9231 20.0137 28.8292V2.38258ZM15.6059 1.66781C16.6185 1.66781 17.6907 1.78693 18.5246 1.96563C18.5246 9.0538 11.6746 14.8911 4.64603 14.8911H1.66781C2.20389 7.62425 8.16033 1.66781 15.6059 1.66781ZM4.64603 16.3207C11.7342 16.3207 18.5246 22.158 18.5246 29.2462C17.6311 29.4249 16.4994 29.544 15.6059 29.544C8.16033 29.544 1.96563 23.5875 1.66781 16.3207H4.64603Z" />
            <path d="M41.696 18.3459V16.3803H48.1886V13.8785H41.696V10.2451H48.6055V7.74341H39.0156V13.1042V18.2268C39.0156 20.9072 40.1474 22.6941 42.8278 23.0515C42.8873 23.0515 42.9469 23.0515 43.0064 23.0515H43.066C43.3638 23.111 43.6021 23.111 43.8999 23.111H48.8438V20.6093H43.9595C43.066 20.6689 41.696 20.252 41.696 18.3459Z" />
            <path d="M50.6875 15.01V23.1704H53.3083V14.9505V7.68359H50.6875V15.01Z" />
            <path d="M60.9945 11.6152C57.6589 11.6152 55.0977 13.9382 55.0977 17.5717V17.6312C55.0977 21.1456 57.5398 23.409 61.0541 23.409C62.7219 23.409 64.3302 22.9325 65.7597 21.3838L64.1515 19.8351C63.3176 20.669 62.4837 21.1456 61.1137 21.1456C59.148 21.1456 57.8376 20.0138 57.7185 18.2269H66.4745C66.534 17.8099 66.534 17.2739 66.534 17.2739C66.534 14.1169 64.3302 11.6152 60.9945 11.6152ZM57.7781 16.3208C57.8972 14.9508 59.2672 13.8787 60.935 13.8787C62.4836 13.8787 63.7941 15.0104 63.7941 16.3208H57.7781Z" />
            <path d="M73.9818 13.9976C75.1731 13.9976 76.0666 14.4145 76.8409 15.2484L78.5087 13.521C77.1983 12.1511 75.6496 11.5554 73.9818 11.5554C70.5867 11.5554 67.9062 14.0571 67.9062 17.5119C67.9062 20.9666 70.6462 23.4683 73.9818 23.4683C75.6496 23.4683 77.1983 22.9322 78.5087 21.5027L76.8409 19.7753C76.0666 20.5497 75.1731 21.0262 73.9818 21.0262C72.0162 21.0262 70.5271 19.5371 70.5271 17.5119C70.5271 15.4867 72.0162 13.9976 73.9818 13.9976Z" />
            <path d="M82.7937 18.3456V14.4144H85.2358V11.9722H82.7937V7.68359H80.1133V13.2231V18.4648C80.1728 20.9665 81.3046 22.6938 83.9254 23.0512C83.985 23.0512 84.0445 23.0512 84.1041 23.0512H84.1637C84.4615 23.1108 84.6998 23.1108 84.9976 23.1108H85.2954V20.6091H84.9976C84.1637 20.6687 82.7937 20.2517 82.7937 18.3456Z" />
            <path d="M90.1208 13.6402V11.9724H87.5V17.4524V23.1706H90.1208V17.2737C90.1208 15.4272 91.3121 14.2954 93.1586 14.2954C93.5756 14.2954 93.933 14.355 94.2903 14.4146V11.8533C94.1712 11.8533 93.7543 11.7937 93.516 11.7937C91.8482 11.7342 90.6569 12.5085 90.1208 13.6402Z" />
            <path d="M100.724 11.6152C97.3288 11.6152 94.6484 14.1765 94.6484 17.5121C94.6484 20.8477 97.3288 23.409 100.724 23.409C104.119 23.409 106.8 20.8477 106.8 17.5121C106.859 14.2361 104.119 11.6152 100.724 11.6152ZM100.724 21.086C98.7584 21.086 97.2693 19.5373 97.2693 17.5717C97.2693 15.6061 98.7584 14.0574 100.724 14.0574C102.69 14.0574 104.179 15.6061 104.179 17.5717C104.179 19.5373 102.69 21.086 100.724 21.086Z" />
            <path d="M108.465 14.7122V23.1704H111.145V14.8313V7.68359H108.465V14.7122Z" />
            <path d="M120.737 18.4652C120.737 20.2521 119.664 20.9669 118.294 20.9669C116.924 20.9669 115.852 20.1926 115.852 18.4652V11.9727H113.172V15.1891V18.7034C113.172 21.9199 115.495 23.409 118.294 23.409C121.094 23.409 123.417 21.8604 123.417 18.6439V15.1891V11.9727H120.737V18.4652Z" />
            <path d="M133.484 19.5969L131.816 17.2143L133.9 14.4148L135.687 11.9727H132.769L130.327 15.3083L127.884 11.9727H125.025L126.693 14.2361L128.897 17.2739L126.574 20.4904L124.668 23.1708H127.527L130.327 19.18L133.186 23.1708H136.045L133.484 19.5969Z" />
        </Svg>
    </div>
)
