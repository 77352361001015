import { compose, defaultProps, mapProps } from 'recompose'
import classnames from 'classnames'
import StepDescription from './StepDescription'
import styles from './styles.module.css'

export default compose(
    defaultProps({
        label: '',
        content: null,
    }),
    mapProps(({ className, ...rest }) => ({
        className: classnames(styles.root, className),
        ...rest,
    }))
)(StepDescription)
