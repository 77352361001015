import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.css'

const Section = React.forwardRef((props, ref) => {
    const {
        children,
        className,
        fullscreen,
        center,
        relative,
        ...other
    } = props

    return (
        <section
            ref={ref}
            className={classnames(
                styles.root,
                fullscreen && styles.fullscreen,
                center && styles.center,
                relative && styles.relative,
                className
            )}
            {...other}
        >
            <div className={styles.inner}>{children}</div>
        </section>
    )
})
Section.propTypes = {
    fullscreen: PropTypes.bool,
    center: PropTypes.bool,
    relative: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}
Section.defaultProps = {
    fullscreen: false,
    center: false,
    relative: false,
    className: '',
}
export default Section
