import React from 'react'

import { H3 } from '../Type'
import AltLogo from '../AltLogo'
import Accordion from './Accordion'

import styles from './styles.module.css';

const faqs = [
    {
        title: "Hur fungerar abonnemanget?",
        description: "<p>Du betalar för ytan som din robotdammsugare städar för varje månad. Du betalar minst 79 kronor och som max 269 kronor i månaden. Om du städar mindre än 79 kvadratmeter kommer minsta kostnaden att vara 79 kronor och om du städar mer än 269 kvadratmeter blir maxbeloppet 269 kronor att betala. Städar du 30 kvadratmeter tre gånger i månaden betalar du för 90 kvadratmeter.</p>"
    },
    {
        title: "Kan jag avsluta abonnemanget när som helst?",
        description: "<p>Ja, det är enkelt att avsluta ditt städabonnemang. Du säger upp avtalet genom att skicka mail till vår support: <a id=\"faq-mail\" href=\"mailto:info.robotics@electrolux.com\">info.robotics@electrolux.com</a>.</p>"
    },
    {
        title: "Vad händer om roboten slutar att fungera?",
        description: "<p>Då tar vi hand om robotdammsugaren på ett miljövänligt sätt och skickar ut en annan till dig.</p>",
    },
    {
        title: "Hur kommer jag i kontakt med er?",
        description: "<p>Vår kundservice besvarar dig inom nästkommande vardag på: <a id=\"faq-mail\" href=\"mailto:info.robotics@electrolux.com\">info.robotics@electrolux.com</a>.</p>"
    },
    {
        title: "Hur fungerar appen?",
        description: "<p>I appen kan du starta eller pausa din städning, sätta städscheman och se hur många kvadratmeter din robotdammsugare har städat.</p>"
    },
    {
        title: "Vilken modell ingår i abonnemanget?",
        description: "<p>Bara det bästa är gott nog, så självklart skickar vi vår toppmodell, PI92-6DGM till alla våra kunder.</p>"
    },
    {
        title: "Hur fungerar garantin?",
        description: "<p>Om din robotdammsugare inte skulle fungera som den ska genom vanlig användning så byter vi ut den mot en annan eller reparerar den utan kostnad. Vi äger robotdammsugaren och tar ansvar för att den alltid fungerar.</p>"
    },
    {
        title: "Vad ingår i abonnemanget?",
        description: "<p>I abonnemanget ingår fri service och service-kit efter 4000 städade kvadratmeter. Ditt abonnemang har ingen bindningstid, du kan när som helst avsluta ditt abonnemang. Du betalar bara för den städning som roboten utfört.</p>"
    }
];

export default () => (
    <div className={styles.root}>
        <div className={styles['faq-block']}>
            <H3 className={styles['faq-block-title']}>Vanliga frågor</H3>
            <Accordion faqs={faqs}/>
        </div>
        <div className={styles.footer}>
            <AltLogo className={styles.logo} />
            <div className={styles.links}>
                <a
                    id="privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.electrolux.se/overlays/data-privacy-statement/"
                >
                    Privacy policy
                </a>
                ,&nbsp;
                <a
                    id="Cookie-policy"
                    target="_blank"
                    href="/Cookie_notice_SV.pdf"
                >
                    Cookie policy
                </a>
                &nbsp;&&nbsp;
                <a
                    id="terms-and-conditions"
                    target="_blank"
                    href="/ALLMÄNNA_ABONNEMANGSVILLKOR_Purei9_2.pdf"
                >
                    Köpvillkor
                </a>
            </div>
        </div>
    </div>
)
