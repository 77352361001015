import { fromRenderProps, compose } from 'recompose'
import Device from '../Device'

import AnimatedRobot from './AnimatedRobot'

export default compose(
    fromRenderProps(Device, ({ height, device }) => ({
        height,
        device,
    }))
)(AnimatedRobot)
