import React from 'react'
import PropTypes from 'prop-types'
import Svg from './'

const ProgressGraphicDesktop = React.forwardRef((props, ref) => {
    const { className, ...other } = props

    return (
        <Svg
            viewBox="0 0 185 195"
            preserveAspectRatio="none"
            className={className}
            ref={ref}
            {...other}
        >
            <g
                id="Page-1"
                stroke="none"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
            >
                <g id="ProgressBarDesktop" stroke="#011E41">
                    <polyline
                        id="Empty"
                        opacity="0.2"
                        vectorEffect="non-scaling-stroke"
                        strokeDasharray="0"
                        points="49.6764828 0 49.6764828 3.50992122 184 3.50992122 184 97.9920613 1 97.9920613 1 194 136.835052 194 136.835052 189.657343"
                    />
                    <polyline
                        id="Filled"
                        vectorEffect="non-scaling-stroke"
                        points="49.6764828 0 49.6764828 3.50992122 184 3.50992122 184 97.9920613 1 97.9920613 1 194 136.835052 194 136.835052 189.657343"
                    />
                </g>
            </g>
        </Svg>
    )
})
ProgressGraphicDesktop.propTypes = {
    className: PropTypes.string,
}
ProgressGraphicDesktop.defaultProps = {
    className: '',
}
export default ProgressGraphicDesktop
