import { mapProps, componentFromProp, defaultProps } from 'recompose'
import classnames from 'classnames'

import styles from './styles.module.css'

const Type = mapProps(({
    type,
    className,
    strong,

    ...rest
}) => ({
    className: classnames(className, styles.root, styles[type], {
        [styles.strong]: strong,
    }),
    ...rest,
}))(componentFromProp('tag'))

export const H1 = defaultProps({ tag: 'h1', type: 'h1' })(Type)
export const H2 = defaultProps({ tag: 'h2', type: 'h2' })(Type)
export const H3 = defaultProps({ tag: 'h3', type: 'h3' })(Type)
export const Preamble = defaultProps({ tag: 'p', type: 'preamble' })(Type)
