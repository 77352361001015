import React from 'react'
import PropTypes from 'prop-types'
import Svg from './'

const BoxIcon = props => {
    const { className, ...other } = props

    return (
        <Svg
            width="93px"
            height="65px"
            viewBox="0 0 93 65"
            className={className}
            {...other}
        >
            <title>BA86D6D1-7B9F-44D4-8763-6E2241B6A76B</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="New-icons" transform="translate(-224.000000, -761.000000)" fill="#001E41" fill-rule="nonzero">
                    <g id="Iconer2-04" transform="translate(224.000000, 761.000000)">
                        <path d="M91.9574471,1.54750414e-05 L30.2428476,1.54750414e-05 C29.9668677,-0.00150534614 29.7016735,0.109124074 29.5059923,0.307420637 C29.3103112,0.505717199 29.2002947,0.775317453 29.2002947,1.0565184 L29.2002947,63.9434816 C29.2002947,64.2246825 29.3103112,64.4942828 29.5059923,64.6925794 C29.7016735,64.8908759 29.9668677,65.0015053 30.2428476,64.9999845 L91.9574471,64.9999845 C92.233427,65.0015053 92.4986212,64.8908759 92.6943024,64.6925794 C92.8899836,64.4942828 93,64.2246825 93,63.9434816 L93,1.0565184 C93,0.775317453 92.8899836,0.505717199 92.6943024,0.307420637 C92.4986212,0.109124074 92.233427,-0.00150534614 91.9574471,1.54750414e-05 L91.9574471,1.54750414e-05 Z M52.0854643,2.11877868 L71.8486412,2.11877868 L71.8486412,10.2416059 L52.0854643,10.2416059 L52.0854643,2.11877868 Z M90.9205602,62.8812213 L31.2797345,62.8812213 L31.2797345,2.11877868 L50.0060245,2.11877868 L50.0060245,11.3038662 C50.0059582,11.5896422 50.118904,11.8633917 50.3193502,12.0632815 C50.5197963,12.2631713 50.7908337,12.372339 51.0712416,12.3661265 L72.9138583,12.3661265 C73.1898423,12.3661307 73.4544409,12.2540304 73.6490586,12.0546502 C73.8436763,11.8552701 73.9522533,11.585063 73.9507452,11.3038662 L73.9507452,2.11877868 L90.9488905,2.11877868 L90.9205602,62.8812213 Z" id="Shape"></path>
                        <path d="M24.7411146,17.6601084 L1.1193587,17.6601084 C0.729581097,17.6894462 0.356439079,17.4941912 0.152759605,17.1543137 C-0.0509198685,16.8144361 -0.0509198685,16.3870333 0.152759605,16.0471558 C0.356439079,15.7072782 0.729581097,15.5120232 1.1193587,15.541361 L24.7411146,15.541361 C25.2856194,15.5823448 25.7068617,16.0444254 25.7068617,16.6007347 C25.7068617,17.157044 25.2856194,17.6191246 24.7411146,17.6601084 L24.7411146,17.6601084 Z" id="Path"></path>
                        <path d="M24.7411146,32.855049 L6.40578188,32.855049 C5.86127705,32.8140652 5.44003476,32.3519846 5.44003476,31.7956752 C5.44003476,31.2393659 5.86127705,30.7772853 6.40578188,30.7363015 L24.7411146,30.7363015 C25.2856194,30.7772853 25.7068617,31.2393659 25.7068617,31.7956752 C25.7068617,32.3519846 25.2856194,32.8140652 24.7411146,32.855049 L24.7411146,32.855049 Z" id="Path"></path>
                        <path d="M24.7411146,47.7786513 L1.1193587,47.7786513 C0.729581097,47.807989 0.356439079,47.6127341 0.152759605,47.2728565 C-0.0509198685,46.9329789 -0.0509198685,46.5055762 0.152759605,46.1656986 C0.356439079,45.825821 0.729581097,45.6305661 1.1193587,45.6599038 L24.7411146,45.6599038 C25.2856194,45.7008876 25.7068617,46.1629682 25.7068617,46.7192776 C25.7068617,47.2755869 25.2856194,47.7376675 24.7411146,47.7786513 Z" id="Path"></path>
                        <path d="M59.3663365,45.9254689 C59.1164626,45.924691 58.8751739,45.8324974 58.6864107,45.665677 L50.7142806,38.6570684 C50.4049988,38.4180126 50.2516897,38.0236649 50.3164135,37.6336592 C50.3811373,37.2436536 50.6531735,36.9225885 51.0224006,36.8004326 C51.3916277,36.6782767 51.7968888,36.7752633 52.0741322,37.0521317 L59.1736909,43.2929108 L70.9930679,25.8926256 C71.1834095,25.5331843 71.5591503,25.3167659 71.9595552,25.3359501 C72.3599601,25.3551344 72.7141111,25.6065234 72.870509,25.9825777 C73.0269069,26.358632 72.9578511,26.7927464 72.6928824,27.0992147 L60.2275759,45.4693898 C60.0599052,45.7170392 59.795608,45.8790359 59.5023217,45.9139226 L59.3663365,45.9254689 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </Svg>
    )
}
BoxIcon.propTypes = {
    className: PropTypes.string,
}
BoxIcon.defaultProps = {
    className: '',
}
export default BoxIcon
