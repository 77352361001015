import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import classes from './styles.module.css'

const Badge = props => {
    const { children, className, ...other } = props

    return (
        <span className={classnames(classes.root, className)} {...other}>
            {children}
        </span>
    )
}
Badge.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}
Badge.defaultProps = {
    className: '',
}
export default Badge
