import React from 'react'

import { H1, H3, Preamble } from '../Type'

import styles from './styles.module.css'

export default () => (
    <div className={styles.root}>
        <div className={styles.container}>
            <H1>
                Automatisk service
                <br />
                och underhåll
            </H1>
            <Preamble>
                Nya borstar kommer med posten,
                <br />
                och om din Pure i9 skulle gå sönder
                <br />
                så reparerar eller ersätter vi den.
            </Preamble>
            <br />
            <br />
            <img className={styles.image} src="/borste.jpg" alt="" />
            <br />
            <br />
            <H1>
                Så långt
                <br />
                räcker 79 m²
            </H1>
            <br />
            <br />
            <img className={styles.area} src="/area.jpg" alt="" />
            <br />
            <br />
            <Preamble>
                Den effektiva städytan är mindre än
                <br />
                boendeytan. Därför räcker 79 m² långt.
            </Preamble>
            <br />
            <br />
            <br />
            <div className={styles.columns}>
                <div>
                    <img className={styles.icon} src="/icon1.png" alt="" />
                    <br />
                    <br />
                    <H3>Från 80 m²</H3>
                    <p>
                        kostar varje m²
                        <br />1 krona extra.
                    </p>
                </div>
                <div>
                    <img className={styles.icon} src="/icon2.png" alt="" />
                    <br />
                    <br />
                    <H3>Max 299 kr/mån</H3>
                    <p>
                        oavsett hur många
                        <br />
                        m² du städar.
                    </p>
                </div>
            </div>
        </div>
    </div>
)
