import { useState } from 'react'

function useFeatureState({ onChange, selected }) {
    const [index, setIndex] = useState(selected)

    function updateIndex(newIndex) {
        onChange(newIndex)
        setIndex(newIndex)
    }

    return {
        index,
        updateIndex,
    }
}

export default useFeatureState
