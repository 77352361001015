import React, { useState } from 'react';
import styles from './accordion-styles.module.css';
import AccordionItem from "./AccordionItem";

const Accordion = (props) => {
    const { faqs } = props;
    const [currentActive, setCurrentActive] = useState(-1);

    const onToggle = (index) => {
        if (index === currentActive) {
            setCurrentActive(-1);
        } else {
            setCurrentActive(index);
        }
    }
    return (
        <div className={styles['faq-accordion-container']}>
            <ul className={styles['accordion']}>
                {faqs.map((faq, index) => {
                    return (<AccordionItem faq={faq} key={index}
                        onToggle={() => {
                            console.log("currentActive", currentActive);
                            onToggle(index);
                        }}
                        active={currentActive === index}/>);
                })}
            </ul>
        </div>
    );
}

export default Accordion;