import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.css'
import ProgressBar from './ProgressBar'
import useVideoState from './state'

const VideoPlayer = React.forwardRef((props, ref) => {
    const {
        className,
        videos,
        posterImages,
        index,
        onUpdateVideoIndex,
        autoPlay,
        ...other
    } = props
    const state = useVideoState({
        index,
        onUpdateVideoIndex,
        videos,
        autoPlay
    })
    return (
        <div
            ref={ref}
            className={classnames(styles.root, className)}
            {...other}
        >
            {posterImages.map((image, i) => (
                <img
                    src={image}
                    key={image}
                    className={classnames(
                        styles['poster-image'],
                        i === index && styles.active
                    )}
                    alt="Video poster"
                />
            ))}
            <video
                autoPlay={autoPlay}
                playsInline
                muted
                ref={state.ref}
                src={videos[index]}
                className={styles.video}
            />
            <ProgressBar
                className={styles.progressBar}
                progress={state.currentTime}
            />
        </div>
    )
})
VideoPlayer.propTypes = {
    className: PropTypes.string,
    onUpdateVideoIndex: PropTypes.func
}
VideoPlayer.defaultProps = {
    className: '',
    onUpdateVideoIndex: () => {}
}
export default VideoPlayer
