import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.css'
import { ProgressGraphicDesktop } from '../Svg'

const ProgressDesktop = React.forwardRef((props, ref) => {
    const { className, animate, ...other } = props

    return (
        <ProgressGraphicDesktop
            ref={ref}
            className={classnames(
                styles.root,
                animate && styles.animate,
                className
            )}
            {...other}
        />
    )
})
ProgressDesktop.propTypes = {
    className: PropTypes.string,
}
ProgressDesktop.defaultProps = {
    className: '',
}
export default ProgressDesktop
