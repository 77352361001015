import { compose, defaultProps, fromRenderProps, withProps, lifecycle, withStateHandlers, onlyUpdateForKeys } from 'recompose'
import debounce from 'debounce'

import IntersectionObserver from '../IntersectionObserver'

import { map, round } from '../../utilities/math'

import Video from './Video'

export default compose(
    defaultProps({
        playing: true,
        progress: 0,
        align: 'center',
    }),
    withStateHandlers({
        node: undefined,
    }, {
        setNode: () => node => ({ node }),
    }),
    fromRenderProps(IntersectionObserver, ({ ref, inView }) => ({
        onRef: ref,
        inView,
    })),
    withProps(({ node, playing, inView }) => ({
        playing: playing && inView,
        update: debounce(progress => {
            node.currentTime = round(map(
                progress,
                0, 1,
                0, node.duration,
            ) * 100) / 100 || 0
        }, 250),
    })),
    lifecycle({
        componentDidUpdate({ node, playing, progress }) {
            if (this.props.node !== node) {
                window.objectFitPolyfill(this.props.node)
            }

            if (this.props.node && this.props.playing !== playing) {
                const video = this.props.node

                const isPlaying = (
                    video.currentTime > 0 &&
                    !video.paused &&
                    !video.ended &&
                    video.readyState > 2
                )

                if (!isPlaying && this.props.playing) {
                    video.play()
                    console.log('Video', this.props.src, 'play')
                }
                
                if (isPlaying && !this.props.playing) {
                    video.pause()
                    console.log('Video', this.props.src, 'pause')
                }
            }

            if (this.props.progress !== progress) {
                this.props.update(this.props.progress)
            }
        }
    }),
    onlyUpdateForKeys([
        'src',
        'align',
    ]),
)(Video)