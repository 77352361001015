import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import classes from './styles.module.css'
import Flex from '../../Flex'

const AppFeature = props => {
    const { children, className, ...other } = props

    return (
        <Flex
            container
            justify="center"
            align="center"
            className={classnames(classes.root, className)}
            {...other}
        >
            {children}
        </Flex>
    )
}
AppFeature.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}
AppFeature.defaultProps = {
    className: '',
}
export default AppFeature
