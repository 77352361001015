import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.css'
import Video from '../Video'

const AppPreview = props => {
    const { className, ...other } = props

    return (
        <div className={classnames(styles.root, className)} {...other}>
            <div className={styles.container}>
                <img src="app-preview.png" alt="Purei9 app" />
                <div className={styles['video-container']}>
                    <Video
                        loop
                        poster="app-preview-poster.png"
                        src="purei9-app-preview-new.mp4"
                    />
                </div>
            </div>
        </div>
    )
}
AppPreview.propTypes = {
    className: PropTypes.string
}
AppPreview.defaultProps = {
    className: ''
}
export default AppPreview
