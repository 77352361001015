import React from 'react'
import Svg from './'

export default ({ className, ...other }) => (
    <Svg
        width={88.81}
        height={76.03}
        viewBox="0 0 88.81 76.03"
        fill="currentColor"
        style={{
            width: 38,
            height: 33,
        }}
        className={className}
        {...other}
    >
        <path d="M61.13,17.71c-1.77-.59-12.24-2-20.88-2s-19.11,1.39-20.88,2c-2.9,1-5.52,8.3-5.66,9.69-.21,2.08.37,8.24,3.83,18.11C21.09,55.59,29,65.88,31.12,66.94c1.13.57,4.19.88,9.11.93l0-2.25,0,2.25c4.91-.05,8-.36,9.11-.93,2.12-1.06,10-11.35,13.57-21.43,3.47-9.87,4-16,3.84-18.11C66.65,26,64,18.68,61.13,17.71ZM44.54,20.34a44.09,44.09,0,0,1-.92,4.37,26.1,26.1,0,0,1-3.37.28,25.67,25.67,0,0,1-3.37-.28A43.47,43.47,0,0,1,36,20.34c1.44-.07,2.88-.11,4.29-.11S43.1,20.27,44.54,20.34Zm2.78,42.58a50.71,50.71,0,0,1-7.07.45,50.71,50.71,0,0,1-7.07-.45,38.56,38.56,0,0,1-4.42-5.59h23A38.56,38.56,0,0,1,47.32,62.92ZM58.71,44a53.57,53.57,0,0,1-4.14,8.81H25.93A52.77,52.77,0,0,1,21.79,44c-3.95-11.25-3.61-16.13-3.6-16.15A20.66,20.66,0,0,1,21,21.92a102.56,102.56,0,0,1,10.43-1.29c1.14,7.17,2.11,7.64,3,8,1.66.76,5.79.82,5.83.82s4.17-.06,5.83-.82c.86-.4,1.84-.87,3-8a102.56,102.56,0,0,1,10.43,1.29,20.8,20.8,0,0,1,2.82,5.93S62.66,32.77,58.71,44Z" />
        <path d="M88.81,14.19A14.19,14.19,0,0,0,63.17,5.81L59.6,5.52l-.18.55H49.27a5.67,5.67,0,0,0-3.72-1H35a5.67,5.67,0,0,0-3.72,1H21.08l-.18-.55-7.44.59-.85,2H7.77l-.57.37C5,9.88.15,19.81,0,23.86S4.9,42.5,5.91,45.4l.52,1.51h.91c5.57,14.64,20,26.94,22.87,28.18,1.82.79,5.17.94,7.56.94,1.12,0,2,0,2.48,0,1.44.06,7.37.26,10-.89,2.91-1.24,17.3-13.54,22.87-28.18h.9l.53-1.51C75.4,43.07,78.8,33.18,80,27.31A14.14,14.14,0,0,0,88.81,14.19Zm-18,28.22H70L69.44,44c-2.52,7.54-7.64,14.21-11.5,18.49-4.55,5-8.59,8.14-9.42,8.52-1.33.57-5.57.65-8.16.52h-.22c-2.51.12-6.84,0-8.15-.52-.85-.38-4.88-3.48-9.43-8.52-3.86-4.28-9-10.95-11.5-18.49l-.51-1.54H9.64C7.3,35.56,4.42,26.14,4.5,24c.1-2.66,3.51-9.4,4.95-11.46h6.12l.94-2.18,1.24-.1.09.28H35l-.14-1H45.66l-.13,1H62.66l.09-.28,1.24.1.94,2.18h6.12c1.44,2.06,4.85,8.8,5,11.46C76.08,26.14,73.19,35.56,70.86,42.41Zm9.37-20.32c-1.06-4.63-5-12.39-6.93-13.65l-.57-.37H67.88l-.24-.58a9.68,9.68,0,1,1,12.59,14.6Z" />
    </Svg>
)
