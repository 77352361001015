import React from "react";
import styles from "./accordion-styles.module.css";
import { H3 } from "../Type";

const AccordionItem = (props) => {
    const { faq, active, onToggle } = props;
    return (<li className={`${styles['accordion-item']} ${active ? '' : styles['close']}`}
        onClick={onToggle}>
        <div className={styles['faq-item-container']}>
            <div className={styles['faq-item-title-container']}>
                <H3>{faq.title}</H3>
                <div className={styles['faq-icon-sign']}><span></span></div>
            </div>
            <div className={styles['faq-item-desc-container']}>
                <div className={styles['faq-item-desc']} dangerouslySetInnerHTML={{__html: faq.description }}></div>
            </div>
        </div>
    </li>);
};

export default AccordionItem;