import React from 'react'
import classnames from 'classnames'

import { pushEvent } from '../../utilities/analytics'

import Faq from '../Faq'
import Down from '../Down'
import Logo from '../Logo'
import Video from '../Video'
import Header from '../Header'
import Cookiebar from '../Cookiebar'
import { H1 } from '../Type'
import ReceiptModal from '../ReceiptModal'
import CheckoutModal from '../CheckoutModal'
import InformationModal from '../InformationModal'
import Section from '../Section'
import Spacing from '../Spacing'
import Flex from '../Flex'
import Testimonial from '../Testimonial'
import Divider from '../Divider'
import StepDescription from '../StepDescription'
import {
    BoxIcon,
    PhoneIcon,
    SqrmIcon,
    ServiceIcon,
    CancelIcon,
    BeneathIcon,
    CornerIcon,
    DoorstepIcon,
    ObstacleIcon,
    CheckIcon,
    WarrantyIcon,
    ScheduleIcon,
    SqrmFollowIcon,
    SqrmCleaningIcon
} from '../Svg'
import ProgressDesktop from '../ProgressDesktop'
import ProgressMobile from '../ProgressMobile'
import VideoPlayer from '../VideoPlayer'
import FeatureSelect from '../FeatureSelect'
import AppPreview from '../AppPreview'
import AppFeature from '../AppPreview/AppFeature'
import IntersectionObserver from '../IntersectionObserver'
import AnimatedRobot from '../AnimatedRobot'
import Vignette from '../Vignette'
import Calculator from '../Calculator';

import styles from './styles.module.css'
import HeaderPopUp from '../HeaderPopUp'

export default ({
    height,
    device,
    videoIndex,
    showCookiebar,
    onUpdateVideoIndex,
    onCookiesAccept,
    onCookiesDeny,
    onSectionInView,
    sectionInView
}) => {
    return (
        <div
            className={classnames(styles.root, device, {
                ie: window.navigator.userAgent.indexOf('Trident/') > 0,
                [styles.cookiebar]: showCookiebar
            })}
        >
            {showCookiebar && (
                <Cookiebar
                    onClickAccept={onCookiesAccept}
                    onClickDeny={onCookiesDeny}
                />
            )}
            <div className={styles.content}>
                <Logo
                    light={sectionInView < 2}
                    onClick={() => {
                        pushEvent('Logo', 'Click')
                    }}
                />
                <IntersectionObserver
                    threshold={0.6}
                    onChange={inView => inView && onSectionInView(0)}
                >
                    {({ inView, ref }) => (
                        <Section
                            style={{
                                height
                            }}
                            ref={ref}
                            center
                            className={styles['section-1']}
                        >
                            <Video loop src={`1_${device}.mp4`} />
                            <Vignette />
                            <div className={styles['title-1-container']}>
                                <H1>
                                    Robotdammsug bekymmersfritt <br></br>
                                </H1>
                                <ul className={styles['top-usps']}>
                                    <li><p>Marknadens bästa dammupptagning</p></li>
                                    <li><p>Utan bindningstid</p></li>
                                    <li><p>Problemfri och effektiv städning</p></li>
                                    <li><p>Service-kit och funktionsgaranti ingår</p></li>
                                </ul>
                            </div>
                            <Header />
                            <Down className={styles.down} />
                        </Section>
                    )}
                </IntersectionObserver>
                <HeaderPopUp sticky visible={sectionInView > 0} />
                <IntersectionObserver
                    onChange={inView =>
                        window.pageYOffset > 0 &&
                        (inView ? onSectionInView(1) : onSectionInView(2))
                    }
                >
                    {({ inView, ref }) => (
                        <Section ref={ref} className={styles['section-2']}>
                            <div className={styles['purei9-lockup-container']}>
                                <div className={styles['purei9-sticky']}>
                                    <IntersectionObserver threshold={0.1}>
                                        {({ inView, ref }) => (
                                            <div
                                                className={
                                                    styles['purei9-container']
                                                }
                                            >
                                                <img
                                                    ref={ref}
                                                    className={classnames(
                                                        styles.purei9,
                                                        inView && styles.visible
                                                    )}
                                                    src={`2_new_${device}.jpg`}
                                                    alt="Pure i9.2"
                                                />
                                            </div>
                                        )}
                                    </IntersectionObserver>
                                    <IntersectionObserver
                                        onChange={inView =>
                                            inView && onSectionInView(1)
                                        }
                                    >
                                        {({ inView, ref }) => (
                                            <Flex
                                                className={
                                                    styles['title-2-wrapper']
                                                }
                                            >
                                                <Flex
                                                    ref={ref}
                                                    container
                                                    justify="center"
                                                    className={
                                                        styles[
                                                            'title-2-container'
                                                        ]
                                                    }
                                                >
                                                    <H1
                                                        className={
                                                            styles['title-2']
                                                        }
                                                    >
                                                        Pure i9.2, helt enkelt <br></br>
                                                        i världsklass
                                                    </H1>
                                                </Flex>
                                            </Flex>
                                        )}
                                    </IntersectionObserver>
                                </div>
                            </div>
                            <Flex className={classnames(styles.secondInner)}>
                                <Flex
                                    className={styles['second-text-container']}
                                >
                                    <IntersectionObserver triggerOnce>
                                        {({ inView, ref }) => (
                                            <div>
                                                <Flex className={styles.secondLogoInner}>
                                                    <div ref={ref} className={classnames(
                                                        styles['pc-for-all-logo-block'],
                                                        inView && styles.visible
                                                        )}>
                                                        <img src="pc_for_alla.png" alt={"logo"}/>
                                                        <p>Pure i9.2 är kliniskt effektiv och städar sig systematiskt genom hemmet riktigt snabbt.</p>
                                                    </div>
                                                </Flex>
                                                <Spacing top={5}>
                                                    <Flex
                                                        ref={ref}
                                                        container
                                                        justify="center"
                                                        className={classnames(
                                                            styles.ratings,
                                                            inView && styles.visible
                                                        )}
                                                    >
                                                        <Flex
                                                            className={
                                                                styles.rating
                                                            }
                                                        >
                                                            <img
                                                                src="m3PI92.png"
                                                                alt="m3 9.2"
                                                            />
                                                        </Flex>
                                                        <Spacing left={2}>
                                                            <Flex
                                                                className={
                                                                    styles.rating
                                                                }
                                                            >
                                                                <img
                                                                    src="m3.png"
                                                                    alt="m3"
                                                                />
                                                            </Flex>
                                                        </Spacing>
                                                        <Spacing left={2}>
                                                            <Flex
                                                                className={
                                                                    styles.rating
                                                                }
                                                            >
                                                                <img
                                                                    src="aftonbladet.png"
                                                                    alt="aftonbladet"
                                                                />
                                                            </Flex>

                                                        </Spacing>
                                                    </Flex>
                                                </Spacing>
                                            </div>
                                        )}
                                    </IntersectionObserver>
                                    <Spacing top={3}>
                                        <Divider className={styles.divider} />
                                    </Spacing>
                                    <IntersectionObserver triggerOnce>
                                        {({ inView, ref }) => (
                                            <div
                                                ref={ref}
                                                className={classnames(
                                                    styles.testimonials,
                                                    inView && styles.visible
                                                )}
                                            >
                                                <Spacing top={6}>
                                                <Spacing top={6}>
                                                    <Testimonial>
                                                        <p>
                                                            <strong>
                                                                Värt varenda krona
                                                            </strong>
                                                        </p>
                                                        <p>
                                                            "Jag gillar inte att lägga tid på att städa så det är värt varenda krona. Jag blev förvånad över att robotdammsugaren var bättre än förväntat. Jag trodde att den skulle gå mitt på golvet och gå vilse."
                                                        </p>
                                                        <Spacing top={1}>
                                                            <img
                                                                src="Rating5.svg"
                                                                alt="rating"
                                                            />
                                                        </Spacing>
                                                    </Testimonial>
                                                </Spacing>
                                                <Spacing top={6}>
                                                    <Testimonial>
                                                        <p>
                                                            <strong>
                                                                Trygg service
                                                            </strong>
                                                        </p>
                                                        <p>
                                                            "Det mest fördelaktiga med att leasa robotdammsugaren är att den kommer bytas ut om något händer den. Jag tror för mig åtminstone, att det är det största värdet."
                                                        </p>
                                                        <Spacing top={1}>
                                                            <img
                                                                src="Rating5.svg"
                                                                alt="rating"
                                                            />
                                                        </Spacing>
                                                    </Testimonial>
                                                </Spacing>
                                                    <Testimonial>
                                                        <p>
                                                            <strong>
                                                                Hjälpsam kundsupport
                                                            </strong>
                                                        </p>
                                                        <p>
                                                            "Priset är okej. Om något händer har du möjlighet att få snabb tillgång till kundsupport - och de är hjälpsamma, det är bra! Jag tycker att det är absolut prisvärt."
                                                        </p>
                                                        <Spacing top={1}>
                                                            <img
                                                                src="Rating4.svg"
                                                                alt="rating"
                                                            />
                                                        </Spacing>
                                                    </Testimonial>
                                                </Spacing>
                                                <Spacing top={6}>
                                                    <Testimonial>
                                                        <p>
                                                            <strong>
                                                                Rimlig och smart leasing
                                                            </strong>
                                                        </p>
                                                        <p>
                                                            "Vi är supernöjda med tjänsten och robotdammsugaren. Så smart att kunna leasa och prova till en rimlig kostnad!"
                                                        </p>
                                                        <Spacing top={1}>
                                                            <img
                                                                src="Rating5.svg"
                                                                alt="rating"
                                                            />
                                                        </Spacing>
                                                    </Testimonial>
                                                </Spacing>
                                                
                                                
                                            </div>
                                        )}
                                    </IntersectionObserver>
                                </Flex>
                            </Flex>
                        </Section>
                    )}
                </IntersectionObserver>
                <Section className={styles['section-3'] + " " + styles['calculator-section']}>
                    <Flex container className={styles['title-3-container']}>
                        <H1 className={styles['title-3']}>Så fungerar det.</H1>
                    </Flex>
                    <Flex className={styles['purei9-2-container']}>
                        <AnimatedRobot className={styles['purei9-2']} />
                    </Flex>
                    <Flex container className={styles['mobile-title-3-container']}>
                        <H1 className={styles['title-3']}>Så fungerar det.</H1>
                    </Flex>
                    <Flex container className={styles['calculator-container']}>
                        <Calculator />
                    </Flex>
                    <Flex container className={styles['steps-container']}>
                        <StepDescription
                            icon={<BoxIcon />}
                            label="Pure i9.2 skickas hem till dig"
                            className={styles['step-description']}
                            description="Testa kostnadsfritt i två veckor - vi tar alla kostnader, även alla fraktkostnader!"
                        />
                        <StepDescription
                            icon={<PhoneIcon />}
                            label="Enkel uppstart"
                            className={styles['step-description']}
                            description="Installeras på ett par minuter med hjälp av appen."
                        />
                        <StepDescription
                            icon={<SqrmIcon />}
                            badge="VARJE MÅNAD"
                            label="Betala 1 kr per städad kvadratmeter"
                            className={styles['step-description']}
                            description="Månadskostnad: 79 kr till 269 kr baserat på hur mycket du städar."
                        />
                        <StepDescription
                            icon={<ServiceIcon />}
                            badge="VID BEHOV"
                            label="Kostnadsfritt service-kit"
                            className={styles['step-description']}
                            description="Alltid bästa städ-prestanda: Efter 4 000 städade kvadratmeter får du nya borstar och filter, värde 695 kr."
                        />
                        <StepDescription
                            icon={<WarrantyIcon />}
                            label="Full garanti"
                            className={styles['step-description']}
                            description="Vi löser givetvis problemet om något händer som gör att städningen inte går att utföra."
                        />
                        <StepDescription
                            icon={<CancelIcon />}
                            label="Ett hållbart kretslopp"
                            className={styles['step-description']}
                            description="Prova en klimatsmart lösning. Vi erbjuder miljöfokus – utan att tumma på kvaliteten."
                        />
                        <IntersectionObserver triggerOnce>
                            {({ inView, ref }) => (
                                <ProgressDesktop
                                    ref={ref}
                                    animate={inView}
                                    className={
                                        styles['progress-graphic-desktop']
                                    }
                                />
                            )}
                        </IntersectionObserver>
                        <IntersectionObserver triggerOnce>
                            {({ inView, ref }) => (
                                <ProgressMobile
                                    ref={ref}
                                    animate={inView}
                                    className={
                                        styles['progress-graphic-mobile']
                                    }
                                />
                            )}
                        </IntersectionObserver>
                    </Flex>
                </Section>
                <Section center className={styles['section-4']}>
                    <H1 className={styles['title-4']}>
                    Batterikapacitet och dammupptagning i världsklass
                    </H1>
                    <Flex
                        container
                        direction="column"
                        className={styles['usp-container']}
                    >
                        <IntersectionObserver triggerOnce>
                            {({ inView, ref }) => (
                                <VideoPlayer
                                    ref={ref}
                                    autoPlay={inView}
                                    videos={[
                                        'feature_1.mp4',
                                        'feature_2.mp4',
                                        'feature_3.mp4',
                                        'feature_4.mp4'
                                    ]}
                                    posterImages={[
                                        'feature_1.jpg',
                                        'feature_2.jpg',
                                        'feature_3.jpg',
                                        'feature_4.jpg'
                                    ]}
                                    index={videoIndex}
                                    onUpdateVideoIndex={onUpdateVideoIndex}
                                    className={styles['feature-videos']}
                                />
                            )}
                        </IntersectionObserver>
                        <Spacing top={6}>
                            <FeatureSelect
                                items={[
                                    {
                                        label: 'Städar runt hinder',
                                        icon: <ObstacleIcon />
                                    },
                                    {
                                        label: 'Tar sig enkelt över trösklar',
                                        icon: <DoorstepIcon />
                                    },
                                    {
                                        label: 'Städar i alla hörn',
                                        icon: <CornerIcon />
                                    },

                                    {
                                        label: 'Städar under möbler',
                                        icon: <BeneathIcon />
                                    }
                                ]}
                                selected={videoIndex}
                                onChange={onUpdateVideoIndex}
                                className={styles['feature-select']}
                            />
                        </Spacing>
                    </Flex>
                    <Spacing top={10}>
                        <H1>Full kontroll i appen</H1>
                    </Spacing>
                    <Flex container className={styles['app-preview-container']}>
                        <Flex
                            container
                            direction="column"
                            justify="start"
                            className={styles['app-features-desktop']}
                        >
                            <AppFeature>
                                <div className={styles['app-feature-surround']}>
                                    <ScheduleIcon />
                                </div>
                                <p>Schemalägg efter din dag och ditt hem</p>
                            </AppFeature>
                            <AppFeature>
                                <div className={styles['app-feature-surround']}>
                                    <SqrmCleaningIcon />
                                </div>
                                <p>Bestäm var och när det ska städas</p>
                            </AppFeature>
                            
                        </Flex>
                        <AppPreview className={styles['app-preview']} />
                        <Flex
                            container
                            align="center"
                            className={styles['app-features-desktop']}
                        >
                            <AppFeature>
                                <div className={styles['app-feature-surround']}>
                                    <SqrmFollowIcon />
                                </div>
                                <p>Följ och se var vi har städat och hur många kvadratmeter som täckts</p>
                            </AppFeature>
                        </Flex>
                    </Flex>
                    <Flex
                        container
                        direction="column"
                        justify="center"
                        className={styles['app-features-mobile']}
                    >
                        <Flex
                            container
                            align="center"
                            className={styles['app-feature']}
                        >
                            <CheckIcon />
                            <p>Schemalägg efter din dag och ditt hem</p>
                        </Flex>
                        <Flex
                            container
                            align="center"
                            className={styles['app-feature']}
                        >
                            <CheckIcon />
                            <p>Bestäm var och när det ska städas</p>
                        </Flex>
                        <Flex
                            container
                            align="center"
                            className={styles['app-feature']}
                        >
                            <CheckIcon />
                            <p>Följ och se var vi har städat och hur många kvadratmeter som täckts</p>
                        </Flex>
                    
                    </Flex>
                    <Spacing top={8}>
                        <Flex container justify="center">
                            <a
                                id="btn-app-store"
                                href="http://itunes.apple.com/app/id1436169315"
                            >
                                <img
                                    className={styles['store-badge']}
                                    src="app-store-badge.svg"
                                    alt="App Store"
                                />
                            </a>
                            <a
                                id="btn-google-play"
                                href="https://play.google.com/store/apps/details?id=com.electrolux.wellbeing"
                            >
                                <img
                                    className={styles['store-badge']}
                                    src="google-play-badge.png"
                                    alt="Google Play"
                                />
                            </a>
                        </Flex>
                    </Spacing>
                </Section>
                <Faq />
            </div>
            <CheckoutModal />
            <ReceiptModal />
            <InformationModal />
        </div>
    )
}
