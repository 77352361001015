import React from 'react'
import styles from './styles.module.css'

export default ({
    id,

    onClick,
    setState,
    openModal,

    ...rest
}) => (
    <div className={styles.root} {...rest}>
        <div id={id} className={styles.frame} />
        <div
            id="btn-checkout-confirm"
            className={styles.button}
            onClick={onClick}
        >
            Beställ Pure i9.2 nu
        </div>
    </div>
)
