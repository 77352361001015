import React from 'react'

import Faq from '../Faq'
import Modal from '../Modal'
import Information from '../Information'

export default props => (
    <Modal header {...props}>
        <Information />
        <Faq />
    </Modal>
)
