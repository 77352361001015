import React from 'react'
import classnames from 'classnames'

import { pushEvent } from '../../utilities/analytics'

import { RobotIcon } from '../Svg'
import { CheckoutModalConsumer } from '../CheckoutModal/context'

import styles from './styles.module.css'

export default ({
    children,
    sticky,
    visible,

    onRef,

    ...rest
}) => (
    <div
        ref={onRef}
        className={classnames(styles.root, {
            [styles.sticky]: sticky,
            [styles.visible]: visible
        })}
        {...rest}
    >
        <div className={styles.info}>
            <div className={styles.infoPrice}>
                Från 79kr/mån för 79 välstädade m²
            </div>
            <div className={styles.infoDetails}>
                — Ingen bindningstid
                <br />— Fri frakt och service
                <br />— Max 269kr / månad
            </div>
        </div>
        <div className={styles.summary}>
            <div className={styles.summaryFrom}>från</div>
            <div className={styles.summaryPrice}>
                <span>79</span>
                <span>kr</span>
            </div>
        </div>
        <CheckoutModalConsumer>
            {({ open }) => (
                <div
                    className={styles.button}
                    id={`btn-checkout${sticky ? '-sticky' : ''}`}
                    onClick={() => {
                        pushEvent('Order Button', 'Click')
                        open()
                    }}
                >
                    <div className={styles.buttonContent}>
                        {/* <RobotIcon /> */}
                        <div>Beställ Pure i9.2</div>
                    </div>
                </div>
            )}
        </CheckoutModalConsumer>
    </div>
)
