import { compose, fromRenderProps, withStateHandlers } from 'recompose'
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { CheckoutModalConsumer } from './context'
import Modal from './Modal'

export default compose(
    withStateHandlers(
        {
            node: undefined,
        },
        {
            onRef: () => node => ({ node }),
        }
    ),
    fromRenderProps(CheckoutModalConsumer, p => p)
    // lifecycle({
    //     componentDidUpdate(prev) {
    //         const { node, isOpen } = this.props

    //         if (prev.isOpen !== isOpen) {
    //             if (isOpen) {
    //                 disableBodyScroll(node)
    //             } else {
    //                 enableBodyScroll(node)
    //             }
    //         }
    //     },
    // }),
)(Modal)
