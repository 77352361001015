export const push = (data) => {
    window.dataLayer.push(data)
}

export const pushPage = (pageType, virtualPage) => push({
    event: 'virtualPageview',
    pageType,
    virtualPage,
})

export const pushEvent = (eventCategory, eventAction) => push({
    event: 'event',
    eventCategory,
    eventAction,
})

export const pushTransaction = transactionId => push({
    transactionId,
    transactionTotal: 79,
    transactionProducts: [{
        sku: 'PUREI9',
        name: 'Pure i9',
        price: 79,
        quantity: 1,
    }],
})
