import React from 'react'
import classnames from 'classnames'

import Back from '../Back'
import Checkout from '../Checkout'

import { CheckIcon } from '../Svg'
import styles from './styles.module.css'
import Flex from '../Flex'
import Spacing from '../Spacing'
import { RobotIcon } from '../Svg'

export default ({
    isOpen,

    onRef,
    close
}) => (
    <div
        ref={onRef}
        className={classnames(styles.root, {
            [styles.open]: isOpen
        })}
    >
        <div id="btn-back" className={styles.close} onClick={close}>
            <Back />
        </div>

        <div className={styles.header}>
            <Flex container direction="column">
                <Flex container align="center">
                    <RobotIcon className={styles['robot-icon']} />
                    <h1>Slutför beställning</h1>
                </Flex>
                <p className={styles.contact}>
                    Behöver du hjälp? Maila oss på{' '}
                    <strong>
                        <a id="faq-mail" href="info.robotics@electrolux.com">
                        info.robotics@electrolux.com
                        </a>
                    </strong>

                </p>
            </Flex>
        </div>

        <div className={styles.content}>
            <hr />
            <Flex
                container
                justify="between"
                wrap
                className={styles['terms-container']}
            >                
                <Flex container className={styles.term}>
                    <CheckIcon />
                    <p>14 dagars riskfri provperiod*</p>
                </Flex>
                <Flex container className={styles.term}>
                    <CheckIcon />
                    <p>Ingen startkostnad</p>
                </Flex>
                <Flex container className={styles.term}>
                    <CheckIcon />
                    <p>Full garanti</p>
                </Flex>
                <Flex container className={styles.term}>
                    <CheckIcon />
                    <p>Regelbundna service-kit (värde 695 kr/st)</p>
                </Flex>
                <Flex container className={styles.term}>
                    <CheckIcon />
                    <p>Fri frakt med PostNord</p>
                </Flex>
            </Flex>
            <hr />
            <Flex
                container
                direction="column"
                className={styles['prices-container']}
            >
                <Flex container justify="between" className={styles.price}>
                    <strong>
                        <p>Minsta månadskostnad</p>
                    </strong>
                    <strong>
                        <p>79 kr</p>
                    </strong>
                </Flex>
                <Flex container justify="between" className={styles.price}>
                    <span>Pris per m² över 79 m²</span>
                    <span>1 kr</span>
                </Flex>
                <Flex container justify="between" className={styles.price}>
                    <span>Högsta månadskostnad</span>
                    <span>269 kr</span>
                </Flex>
            </Flex>
            <hr />
            <Spacing top={2}>
                <span>
                *Du läste rätt, vi tar kostnaden för allt. Beställ och prova det kostar inte ens frakt. Uppsägning efter 14 dagar innebär att du betalar för dina städade kvadratmeter, inget mer.
                </span>
            </Spacing>
            <a
                target="_blank"
                className={styles.link}
                href="/ALLMÄNNA_ABONNEMANGSVILLKOR_2022-04.pdf"
            >
                Fullständiga köpvillkor
            </a>
            
            <br />
            <br />
            <Checkout />
        </div>
    </div>
)
