import React from 'react'
import PropTypes from 'prop-types'
import Svg from './'

const ObstacleIcon = props => {
    const { className, ...other } = props

    return (
        <Svg
            width="62px"
            height="62px"
            viewBox="0 0 62 62"
            className={className}
            {...other}
        >
            <g id="ObstacleIcon" fill="#011E41" fillRule="nonzero">
                <g id="obstacles" transform="translate(9.000000, 9.000000)">
                    <path
                        d="M30.6,30.6 L14.4,30.6 L14.4,14.4 L30.6,14.4 L30.6,30.6 Z M16.2,28.8 L28.8,28.8 L28.8,16.2 L16.2,16.2 L16.2,28.8 Z"
                        id="Shape"
                    />
                    <path
                        d="M23.4,44.1 L21.6,44.1 L21.6,41.1435 L23.4,41.1435 L23.4,44.1 Z M22.5,37.8 L22.5,37.8 L22.5,37.0935 L21.6,37.0935 L21.6,36 L22.5,36 C23.7053904,35.999961 24.9054181,35.8395538 26.0685,35.523 L26.55,37.26 C25.2302139,37.6202589 23.868071,37.8018779 22.5,37.8 L22.5,37.8 Z M30.4785,35.55 L29.5425,34.02 C30.6245139,33.3572433 31.6076888,32.5452529 32.463,31.608 L33.813,32.8275 C32.8346992,33.885114 31.7139455,34.8014059 30.483,35.55 L30.4785,35.55 Z M36.216,29.2905 L34.6005,28.4895 C35.1655417,27.3520433 35.5655649,26.1398519 35.7885,24.8895 L37.5615,25.2045 C37.3072588,26.6229659 36.8543011,27.9985032 36.216,29.2905 L36.216,29.2905 Z M35.928,21.114 C35.803213,19.8550445 35.5001848,18.6202045 35.028,17.4465 L36.693,16.7715 C37.2339202,18.1025381 37.5809599,19.504336 37.7235,20.934 L35.928,21.114 Z M33.1245,14.1705 C32.3412498,13.1740507 31.4234949,12.2911281 30.3975,11.547 L31.4505,10.089 C32.6142092,10.9267992 33.6548205,11.9234836 34.542,13.05 L33.1245,14.1705 Z M27.0495,9.783 C25.8551257,9.35815459 24.6068647,9.10365477 23.3415,9.027 L23.454,7.227 C24.8889598,7.31980968 26.3037759,7.6136794 27.657,8.1 L27.0495,9.783 Z M23.4,4.95 L21.6,4.95 L21.6,0.9 L23.4,0.9 L23.4,4.95 Z"
                        id="Shape"
                    />
                    <path
                        d="M22.5,45 C10.0735931,45 1.52179594e-15,34.9264069 0,22.5 C-1.52179594e-15,10.0735931 10.0735931,2.28269391e-15 22.5,0 C34.9264069,-2.28269391e-15 45,10.0735931 45,22.5 C44.9851225,34.9202392 34.9202392,44.9851225 22.5,45 Z M22.5,1.8 C11.0677057,1.8 1.8,11.0677057 1.8,22.5 C1.8,33.9322943 11.0677057,43.2 22.5,43.2 C33.9322943,43.2 43.2,33.9322943 43.2,22.5 C43.1851254,11.0738726 33.9261274,1.81487456 22.5,1.8 Z"
                        id="Shape"
                    />
                </g>
            </g>
        </Svg>
    )
}
ObstacleIcon.propTypes = {
    className: PropTypes.string,
}
ObstacleIcon.defaultProps = {
    className: '',
}
export default ObstacleIcon
