import 'react-app-polyfill/ie11'
import 'objectFitPolyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import Site from './components/Site'
import * as serviceWorker from './serviceWorker'
import './index.css'
import './range-slider.css'

ReactDOM.render(<Site />, document.getElementById('root'))
serviceWorker.unregister()
