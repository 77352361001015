import React from 'react'
import PropTypes from 'prop-types'
import Svg from './'

const DoorstepIcon = props => {
    const { className, ...other } = props

    return (
        <Svg
            width="62px"
            height="62px"
            viewBox="0 0 62 62"
            className={className}
            {...other}
        >
            <g id="DoorstepIcon" fill="#011E41" fillRule="nonzero">
                <g transform="translate(9.000000, 9.000000)">
                    <polygon
                        id="Path"
                        points="44.1 28.8 28.8 28.8 28.8 23.4 16.2 23.4 16.2 28.8 0.9 28.8 0.9 27 14.4 27 14.4 21.6 30.6 21.6 30.6 27 44.1 27"
                    />
                    <path
                        d="M37.8,27.9 L36,27.9 C35.9992885,27.335936 35.9647264,26.7724231 35.8965,26.2125 L37.6965,25.992 C37.7692014,26.6253515 37.8037638,27.2625006 37.8,27.9 Z"
                        id="Path"
                    />
                    <path
                        d="M35.001,22.7925 C34.5524105,21.6963819 33.9607776,20.6644289 33.2415,19.7235 L34.6725,18.63 C35.4878758,19.6978847 36.1582057,20.8690706 36.666,22.113 L35.001,22.7925 Z M10.0035,22.7925 L8.343,22.1085 C8.85519834,20.861145 9.53004419,19.6869738 10.35,18.6165 L11.781,19.71 C11.0564586,20.6489852 10.4588139,21.6793549 10.0035,22.7745 L10.0035,22.7925 Z M30.7485,17.2305 C29.8135799,16.4987548 28.7862181,15.8935344 27.693,15.4305 L28.386,13.77 C29.6255267,14.2848923 30.7908089,14.9628747 31.851,15.786 L30.7485,17.2305 Z M14.265,17.2305 L13.1625,15.804 C14.230209,14.9739609 15.4028434,14.2885371 16.65,13.7655 L17.343,15.426 C16.2426985,15.8793482 15.2076146,16.477094 14.265,17.2035 L14.265,17.2305 Z M24.2775,14.5305 C23.6884829,14.4482555 23.094713,14.4046623 22.5,14.4 L22.5,14.4 C21.9116752,14.3991207 21.3239221,14.4367009 20.7405,14.5125 L20.5065,12.7125 C21.1678922,12.6322018 21.8337729,12.5946239 22.5,12.6 L22.5,12.6 C23.1696052,12.6005935 23.8384813,12.6441832 24.5025,12.7305 L24.2775,14.5305 Z"
                        id="Shape"
                    />
                    <path
                        d="M9,27.9 L7.2,27.9 C7.20071653,27.2622296 7.23978706,26.6250795 7.317,25.992 L9.117,26.2125 C9.0442601,26.7721513 9.0051898,27.3356653 9,27.9 L9,27.9 Z"
                        id="Path"
                    />
                    <path
                        d="M22.5,45 C10.0735931,45 1.52179594e-15,34.9264069 0,22.5 C-1.52179594e-15,10.0735931 10.0735931,2.28269391e-15 22.5,0 C34.9264069,-2.28269391e-15 45,10.0735931 45,22.5 C44.9851225,34.9202392 34.9202392,44.9851225 22.5,45 Z M22.5,1.8 C11.0677057,1.8 1.8,11.0677057 1.8,22.5 C1.8,33.9322943 11.0677057,43.2 22.5,43.2 C33.9322943,43.2 43.2,33.9322943 43.2,22.5 C43.1851254,11.0738726 33.9261274,1.81487456 22.5,1.8 Z"
                        id="Shape"
                    />
                </g>
            </g>
        </Svg>
    )
}
DoorstepIcon.propTypes = {
    className: PropTypes.string,
}
DoorstepIcon.defaultProps = {
    className: '',
}
export default DoorstepIcon
