import React from 'react'
import Svg from '../Svg'

export default props => (
    <Svg
        width={63}
        height={63}
        viewBox="0 0 63 63"
        fill="#011E41"
        {...props}
    >
        <path d="M0 62.9229L62.5 62.9229L62.5 0.422852L0 0.422852L0 62.9229ZM40.0763 5.19384C51.1689 8.77209 59.1603 19.2683 59.1603 31.6728C59.1603 43.8389 51.4074 54.3351 40.0763 58.1519L40.0763 5.19384ZM31.25 3.76255C33.2777 3.76255 35.4246 4.0011 37.0945 4.35892C37.0945 18.5526 23.3779 30.2416 9.30343 30.2416H3.33969C4.41317 15.69 16.3406 3.76255 31.25 3.76255ZM9.30343 33.1041C23.4971 33.1041 37.0945 44.7931 37.0945 58.9868C35.3053 59.3446 33.0391 59.5832 31.25 59.5832C16.3406 59.5832 3.93607 47.6557 3.33969 33.1041H9.30343Z" />
    </Svg>
)
