import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.css'

const Flex = React.forwardRef((props, ref) => {
    const {
        children,
        className,
        container,
        direction,
        align,
        justify,
        grow,
        shrink,
        spacing,
        wrap,
        ...other
    } = props

    return (
        <div
            ref={ref}
            className={classnames(
                styles.root,
                container && styles.container,
                direction && styles[direction],
                align && styles[`align-${align}`],
                justify && styles[`justify-${justify}`],
                wrap && styles[`wrap-${wrap}`],
                className
            )}
            style={{
                flexGrow: grow,
                flexShrink: shrink,
                marginLeft: spacing && `calc(-${spacing} / 2)`,
                width: spacing && `calc(100% + ${spacing})`,
            }}
            {...other}
        >
            {children}
        </div>
    )
})
Flex.propTypes = {
    children: PropTypes.node.isRequired,
    container: PropTypes.bool,
    className: PropTypes.string,
    direction: PropTypes.oneOf([
        'row',
        'column',
        'row-reverse',
        'column-reverse',
    ]),
    align: PropTypes.oneOf(['start', 'center', 'end', 'baseline', 'stretch']),
    justify: PropTypes.oneOf([
        'start',
        'center',
        'end',
        'baseline',
        'stretch',
        'between',
    ]),
    grow: PropTypes.number,
    shrink: PropTypes.number,
    wrap: PropTypes.bool,
}
Flex.defaultProps = {
    className: '',
    container: false,
    direction: null,
    align: null,
    justify: null,
    grow: null,
    shrink: null,
    wrap: null,
}
export default Flex
