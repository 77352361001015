export const map = (value, low1, high1, low2, high2) => (
    low2 + (high2 - low2) * (value - low1) / (high1 - low1)
)

export const lerp = (start, end, amt) => ((1 - amt) * start) + (amt * end)

export const round = Math.round

export const clamp = (value, min, max) => Math.max(min, Math.min(max, value))

export const rand = (min, max) => Math.random() * (max - min) + min

const seeds = {}
export const randSeed = (seed, ...args) => {
    return seeds[seed] || (seeds[seed] = rand(...args))
}
