import React from 'react'
import PropTypes from 'prop-types'
import Svg from './'

const CornerIcon = props => {
    const { className, ...other } = props

    return (
        <Svg
            width="62px"
            height="62px"
            viewBox="0 0 62 62"
            className={className}
            {...other}
        >
            <g id="CornerIcon" fill="#011E41" fillRule="nonzero">
                <g transform="translate(9.000000, 9.000000)">
                    <polygon
                        id="Path"
                        points="34.2 34.2 3.6 34.2 3.6 32.4 32.4 32.4 32.4 3.6 34.2 3.6"
                    />
                    <path
                        d="M34.1325,9.963 L32.3325,9.837 C32.4,9.2655 32.4,8.6805 32.4,8.1 L34.2,8.1 C34.2,8.7255 34.2,9.351 34.1325,9.963 Z"
                        id="Path"
                    />
                    <path
                        d="M12.897,33.75 L12.5685,31.9815 C13.765109,31.7616346 14.9430708,31.4502194 16.092,31.05 L16.686,32.751 C15.4496546,33.1779395 14.1831264,33.5118697 12.897,33.75 L12.897,33.75 Z M19.3995,31.635 L18.621,30.0105 C19.7139467,29.4889912 20.7668195,28.8873497 21.771,28.2105 L22.7925,29.7 C21.7111796,30.4284576 20.5770338,31.0752516 19.3995,31.635 L19.3995,31.635 Z M25.1235,27.9 L23.949,26.55 C24.8780386,25.744111 25.7473249,24.8718168 26.55,23.94 L27.9,25.1145 C27.0450901,26.1107841 26.1170122,27.0418704 25.1235,27.9 L25.1235,27.9 Z M29.7,22.7835 L28.2105,21.771 C28.8958819,20.7680304 29.5050899,19.7150783 30.033,18.621 L31.6575,19.404 C31.0889374,20.5770746 30.4346641,21.706636 29.7,22.7835 Z M32.76,16.65 L31.05,16.0785 C31.4517642,14.9281673 31.7646824,13.7487063 31.986,12.5505 L33.7545,12.879 C33.5169687,14.1589217 33.1845514,15.4193999 32.76,16.65 Z"
                        id="Shape"
                    />
                    <path
                        d="M8.1,34.2 L8.1,32.4 C8.676,32.4 9.261,32.4 9.837,32.337 L9.963,34.137 C9.342,34.2 8.7165,34.2 8.1,34.2 Z"
                        id="Path"
                    />
                    <path
                        d="M22.5,45 C10.0735931,45 1.52179594e-15,34.9264069 0,22.5 C-1.52179594e-15,10.0735931 10.0735931,2.28269391e-15 22.5,0 C34.9264069,-2.28269391e-15 45,10.0735931 45,22.5 C44.9851225,34.9202392 34.9202392,44.9851225 22.5,45 Z M22.5,1.8 C11.0677057,1.8 1.8,11.0677057 1.8,22.5 C1.8,33.9322943 11.0677057,43.2 22.5,43.2 C33.9322943,43.2 43.2,33.9322943 43.2,22.5 C43.1851254,11.0738726 33.9261274,1.81487456 22.5,1.8 Z"
                        id="Shape"
                    />
                </g>
            </g>
        </Svg>
    )
}
CornerIcon.propTypes = {
    className: PropTypes.string,
}
CornerIcon.defaultProps = {
    className: '',
}
export default CornerIcon
