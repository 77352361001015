import React from 'react'
import PropTypes from 'prop-types'
import Svg from './'

const BeneathIcon = props => {
    const { className, ...other } = props

    return (
        <Svg
            width="62px"
            height="62px"
            viewBox="0 0 62 62"
            className={className}
            {...other}
        >
            <g id="BeneathIcon" fill="#011E41" fillRule="nonzero">
                <g id="beneath" transform="translate(9.000000, 9.000000)">
                    <polygon
                        id="Path"
                        points="37.8 32.4 28.8 32.4 28.8 12.6 16.2 12.6 16.2 32.4 7.2 32.4 7.2 6.3 9 6.3 9 30.6 14.4 30.6 14.4 10.8 30.6 10.8 30.6 30.6 36 30.6 36 6.3 37.8 6.3"
                    />
                    <rect
                        id="Rectangle"
                        x="15.3"
                        y="25.2"
                        width="2.025"
                        height="1.8"
                    />
                    <rect
                        id="Rectangle"
                        x="20.2815"
                        y="25.2"
                        width="4.437"
                        height="1.8"
                    />
                    <rect
                        id="Rectangle"
                        x="27.675"
                        y="25.2"
                        width="2.025"
                        height="1.8"
                    />
                    <path
                        d="M8.1,27 L5.85,27 L5.85,25.2 L8.1,25.2 L8.1,27 Z M4.5,27 L0.9,27 L0.9,25.2 L4.5,25.2 L4.5,27 Z"
                        id="Shape"
                    />
                    <path
                        d="M44.1,27 L41.85,27 L41.85,25.2 L44.1,25.2 L44.1,27 Z M40.5,27 L36.9,27 L36.9,25.2 L40.5,25.2 L40.5,27 Z"
                        id="Shape"
                    />
                    <path
                        d="M22.5,45 C10.0735931,45 1.52179594e-15,34.9264069 0,22.5 C-1.52179594e-15,10.0735931 10.0735931,2.28269391e-15 22.5,0 C34.9264069,-2.28269391e-15 45,10.0735931 45,22.5 C44.9851225,34.9202392 34.9202392,44.9851225 22.5,45 Z M22.5,1.8 C11.0677057,1.8 1.8,11.0677057 1.8,22.5 C1.8,33.9322943 11.0677057,43.2 22.5,43.2 C33.9322943,43.2 43.2,33.9322943 43.2,22.5 C43.1851254,11.0738726 33.9261274,1.81487456 22.5,1.8 Z"
                        id="Shape"
                    />
                    <polygon
                        id="Path"
                        points="30.6 5.4 14.4 5.4 14.4 2.7 16.2 2.7 16.2 3.6 28.8 3.6 28.8 2.7 30.6 2.7"
                    />
                </g>
            </g>
        </Svg>
    )
}
BeneathIcon.propTypes = {
    className: PropTypes.string,
}
BeneathIcon.defaultProps = {
    className: '',
}
export default BeneathIcon
