import { useState, useRef, useEffect } from 'react'
import raf from '../../utilities/raf'

function useVideoState({ index, onUpdateVideoIndex, videos, autoPlay }) {
    const ref = useRef(null)
    const [currentTime, setCurrentTime] = useState(0)
    useEffect(
        () => {
            raf(update)
            if (autoPlay) ref.current.play()
            ref.current.addEventListener('ended', finishedHandler)
            return () => {
                ref.current.removeEventListener('ended', finishedHandler)
            }
        },
        [index, autoPlay]
    )

    function update() {
        setCurrentTime(ref.current.currentTime / ref.current.duration)
    }

    function finishedHandler() {
        if (index < videos.length - 1) {
            onUpdateVideoIndex(index + 1)
        } else {
            onUpdateVideoIndex(0)
        }
    }

    return {
        currentTime,
        ref,
    }
}

export default useVideoState
