import React from 'react'
import PropTypes from 'prop-types'
import Svg from './'

const CancelIcon = props => {
    const { className, ...other } = props

    return (
        <Svg
            width="93px"
            height="93px"
            viewBox="0 0 93 93"
            className={className}
            {...other}
        >
            <title>ECB3D290-E699-4876-B5C4-051C7B553F9C@1x</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="New-icons" transform="translate(-707.000000, -1637.000000)" fill="#001E41" fill-rule="nonzero">
                    <g id="Iconer2_Rityta-1" transform="translate(707.000000, 1637.000000)">
                        <path d="M54.7434094,92.2532847 C32.9232093,96.201358 11.3535776,84.1829361 3.23291582,63.5520603 C-4.88774592,42.9211845 2.70392638,19.4280244 21.3626082,7.44798363 L8.03524107,5.41800919 C7.37761659,5.30484796 6.93155566,4.68623409 7.03197733,4.02664572 C7.13239899,3.36705736 7.74235613,2.90917013 8.40387037,2.99678269 L25.1453119,5.55409686 C25.8038136,5.65621061 26.2598873,6.26589201 26.1718028,6.92631422 L23.9543557,23.4099335 C23.8723663,24.0150421 23.3571119,24.4673298 22.7463859,24.4702833 L22.5762493,24.4702833 C21.9100384,24.3736531 21.4451132,23.759746 21.5327448,23.0923956 L23.4155898,9.04134346 C5.39277784,20.1326475 -2.18718076,42.4760048 5.36743988,62.2416689 C12.9220605,82.0073329 33.4734617,93.6020366 54.3010542,89.8490692 C54.7436989,89.7379665 55.2114164,89.8821063 55.5146956,90.2230855 C55.8179749,90.5640647 55.9065258,91.0453418 55.744469,91.4719117 C55.5824122,91.8984816 55.1966203,92.19961 54.7434094,92.2532847 Z" id="Path"></path>
                        <path d="M87.0580212,87.7907432 C87.0051465,88.4289556 86.473527,88.9211177 85.8330376,88.9248071 C85.8011183,88.930095 85.7685462,88.930095 85.7366269,88.9248071 L67.8949686,87.4732053 C67.5599897,87.4482022 67.2501656,87.2861345 67.0386144,87.02525 C66.8297161,86.7631679 66.7356323,86.4277921 66.7777383,86.0953176 L68.9951853,69.6116983 C69.0539431,69.1791878 69.3390158,68.8108736 69.7430193,68.645496 C70.1470227,68.4801185 70.6085792,68.5428024 70.9538248,68.8099353 C71.2990704,69.0770682 71.4755541,69.5080663 71.4167963,69.9405768 L69.5339512,83.9859587 C87.5971164,72.9129035 95.2079343,50.5448579 87.6458391,30.7554727 C80.0837439,10.9660874 59.4945085,-0.629325349 38.6484869,3.16122196 C38.2058422,3.27232463 37.7381247,3.12818491 37.4348455,2.78720568 C37.1315662,2.44622645 37.0430153,1.96494934 37.2050721,1.53837943 C37.3671288,1.11180953 37.7529208,0.810681207 38.2061317,0.757006412 C59.9574737,-3.20677157 81.4892903,8.70508135 89.6851831,29.2362982 C97.8810759,49.7675151 90.4701638,73.2293989 71.9669046,85.3298244 L85.9634757,86.4638884 C86.6282412,86.5354564 87.1142432,87.1246088 87.0580212,87.7907432 L87.0580212,87.7907432 Z" id="Path"></path>
                        <path d="M56.0874885,25.7914678 L47.9492877,17.688581 C47.8962212,17.6331927 47.8372699,17.5837575 47.7734799,17.5411527 C47.7059233,17.4957271 47.6336715,17.4577059 47.5579736,17.4277463 C47.4101473,17.3611527 47.2493702,17.3282277 47.0872623,17.3310703 C46.925102,17.3276503 46.7641985,17.3606012 46.616551,17.4277463 C46.5408531,17.4577059 46.4686013,17.4957271 46.4010447,17.5411527 C46.3372547,17.5837575 46.2783035,17.6331927 46.2252369,17.688581 L37.0889014,26.8177957 C27.3413642,36.5854241 27.3413642,52.3989401 37.0889014,62.1665685 L46.2252369,71.3014535 C46.6986593,71.7624186 47.4531804,71.7624186 47.9266029,71.3014535 L56.0874885,63.1928964 C66.4232137,52.8583903 66.4232137,36.1032926 56.0874885,25.7687865 L56.0874885,25.7914678 Z M48.3122458,21.5047061 L54.3294103,27.5209153 C55.6262265,28.8046104 56.7682465,30.2357148 57.7321423,31.7849957 L48.3122458,39.4569382 L48.3122458,21.5047061 Z M48.3122458,42.609636 L58.9230985,33.9510578 C60.4469015,37.0679117 61.2732665,40.4792706 61.3447095,43.9478314 L48.3009034,54.0410004 L48.3122458,42.609636 Z M38.8186235,28.5472432 L45.8792924,21.5047061 L45.8792924,39.5476633 L36.2382184,31.6715893 C37.0026466,30.5546843 37.866281,29.5089986 38.8186235,28.5472432 L38.8186235,28.5472432 Z M34.9281665,33.7752779 L45.8792924,42.7003611 L45.8792924,54.1203849 L32.3420902,43.6699857 C32.313754,43.64235 32.2812128,43.6193833 32.2456794,43.6019419 C32.3711073,40.1635602 33.2891284,36.8006048 34.9281665,33.7752779 L34.9281665,33.7752779 Z M32.336419,46.7376287 L45.8792924,57.2220498 L45.8792924,67.4853284 L38.8186235,60.4427914 C35.1302189,56.7666738 32.8381021,51.9205085 32.336419,46.7376287 L32.336419,46.7376287 Z M54.3294103,61.4804599 L48.3122458,67.4853284 L48.3122458,57.136995 L61.1915866,47.1458917 C60.604432,52.5629259 58.1832316,57.6166294 54.3294103,61.4691192 L54.3294103,61.4804599 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </Svg>
    )
}
CancelIcon.propTypes = {
    className: PropTypes.string,
}
CancelIcon.defaultProps = {
    className: '',
}
export default CancelIcon
