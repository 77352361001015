import React from 'react'

import Svg from '../Svg'

export default props => (
    <Svg
        width={40}
        height={40}
        viewBox="0 0 40 40"
        stroke="#ffffff"
        strokeWidth={2}
        {...props}
    >
        <path d="M20 30L10 20L20 10" />
        <path d="M10 20H30" />
    </Svg>
)
