import React from 'react';
import Flex from '../Flex';
import styles from './style.module.css';

const CalculatorRangeSlider = (props) => {
    const { 
        leftLabel,
        sliderValue,
        min,
        max,
        step,
        hasUnit,
        onSliderChange
    } = props;

    return (
        <div className={styles['calculator-slider-block']}>
            <Flex 
                container
                direction="row"
                className={styles['calculator-labels-row']}>
                <div className={styles['lable-col-left']}><label className={styles['calculator-left-label']}>{leftLabel}</label></div>
                <div className={styles['lable-col-right']}><label className={styles['calculator-right-label']}><span>{sliderValue}</span> { hasUnit && <>m<sup>2</sup></> }</label></div>
            </Flex>
                <input type="range" min={min} max={max} step={step} className={styles['range-slider']} value={sliderValue} onChange={(event) => {
                    onSliderChange(event.target.value);
                }}/>
        </div>
    );
}

export default CalculatorRangeSlider;