import React from 'react'
import { compose, withStateHandlers, withProps } from 'recompose'
import { withRouter } from 'react-router-dom'

import { pushPage } from '../../utilities/analytics'

export default (pageType, virtualPage) => {
    const { Provider, Consumer } = React.createContext()

    return {
        Provider: compose(
            withRouter,
            withStateHandlers(
                ({ history }) => ({
                    isOpen:
                        history.location.pathname.search(virtualPage) !== -1,
                }),
                {
                    open: (state, { history }) => () => {
                        history.push(virtualPage)
                        pushPage(pageType, virtualPage)
                        return { isOpen: true }
                    },
                    close: (state, { history }) => () => {
                        history.push('/')
                        return { isOpen: false }
                    },
                }
            ),
            withProps(({ isOpen, open, close, history }) => ({
                value: { isOpen, open, close, history },
            }))
        )(Provider),
        Consumer,
    }
}
