import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.css'
import { ProgressGraphicMobile } from '../Svg'

const ProgressMobile = React.forwardRef((props, ref) => {
    const { className, animate, ...other } = props

    return (
        <ProgressGraphicMobile
            ref={ref}
            className={classnames(
                styles.root,
                animate && styles.animate,
                className
            )}
            {...other}
        />
    )
})
ProgressMobile.propTypes = {
    className: PropTypes.string,
}
ProgressMobile.defaultProps = {
    className: '',
}
export default ProgressMobile
