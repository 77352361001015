import React from 'react'

import Svg from '../Svg'

export default props => (
    <Svg
        width={40}
        height={40}
        viewBox="0 0 40 40"
        fill="none"
        strokeWidth={2}
        {...props}
    >
        <circle fill="#ffffff" cx="13.01" cy="22.51" r="1.51" />
        <circle fill="#ffffff" cx="20.51" cy="27.49" r="1.51" />
        <circle fill="#ffffff" cx="20.51" cy="20.49" r="1.51" />
        <circle fill="#ffffff" cx="27.51" cy="20.49" r="1.51" />
        <circle fill="#ffffff" cx="22.51" cy="13.51" r="1.51" />
        <path stroke="#ffffff" d="M20,8a11.87,11.87,0,0,0-4.27.8A5.6,5.6,0,0,1,16,10.5c0,.22,0,.43,0,.65a1.69,1.69,0,0,1,1,.48,1.76,1.76,0,0,1,0,2.49A1.8,1.8,0,0,1,14.5,14h0a2.09,2.09,0,0,1-.07,2.62,1.77,1.77,0,0,1-2.49,0,1.85,1.85,0,0,1-.43-.72,5.49,5.49,0,0,1-1,.1,5.6,5.6,0,0,1-1.7-.27A11.87,11.87,0,0,0,8,20,12,12,0,1,0,20,8Z" />
    </Svg>
)
