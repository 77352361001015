import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import classes from './styles.module.css'

const Vignette = props => {
    const { className, ...other } = props

    return <div className={classnames(classes.root, className)} {...other} />
}
Vignette.propTypes = {
    className: PropTypes.string,
}
Vignette.defaultProps = {
    className: '',
}
export default Vignette
