import debounce from 'debounce'
import { toRenderProps, lifecycle } from 'recompose'

let lastWidth = window.innerWidth
let lastHeight = window.innerHeight

const getProps = () => {
    const ww = window.innerWidth
    const wh = window.innerHeight
    const device = ww > 700 ? 'desktop' : 'mobile'
    let width, height
    width = ww
    if (device === 'mobile' && ww === lastWidth) {
        height = lastHeight
    } else {
        height = wh
        lastWidth = ww
        lastHeight = wh
    }

    return { width, height, device }
}

export default toRenderProps(
    lifecycle({
        state: getProps(),
        componentDidMount() {
            const resize = () => {
                this.setState(getProps())
            }

            window.addEventListener('resize', debounce(resize, 250))
            resize()
        },
    })
)
