import React from 'react'

import Svg from '../Svg'

export default props => (
    <Svg
        width={30}
        height={19}
        viewBox="0 0 30 19"
        stroke="#ffffff"
        strokeWidth={2}
        {...props}
    >
        <path d="M1.69922 1.30005L14.9992 16.6L28.2992 1.30005" />
    </Svg>
)
