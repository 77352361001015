import raf from 'raf'

let animateFns = []

raf(function tick() {
    animateFns.forEach(fn => fn())
    raf(tick)
})

export const rafd = fn => animateFns = animateFns.filter(f => f !== fn)
export default fn => animateFns.push(fn)
