import React from 'react'
import classnames from 'classnames'
import Badge from '../Badge'
import styles from './styles.module.css'
import Flex from '../Flex'

const StepDescription = props => {
    const {
        className,
        icon,
        content,
        badge,
        label,
        description,
        ...other
    } = props

    return (
        <Flex
            container
            direction="column"
            align="center"
            className={classnames(className)}
            {...other}
        >
            <div className={styles['icon-block']}>
                {React.cloneElement(icon, { className: styles.icon })}
            </div>
            {badge && <Badge>{badge}</Badge>}
            <p className={styles.label}>
                <strong>{label}</strong>
            </p>
            <p className={styles.description}>{description}</p>
        </Flex>
    )
}
export default StepDescription
