import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.css'
import Flex from '../Flex'
import useFeatureState from './state'

const FeatureSelect = props => {
    const { className, items, onChange, selected, ...other } = props
    const state = useFeatureState({
        onChange,
        selected,
    })

    return (
        <div className={classnames(styles.root, className)} {...other}>
            <p className={styles['description-mobile']}>
                {items[selected].label}
            </p>
            <Flex container justify="center" className={styles.features}>
                {items.map((item, i) => (
                    <Flex
                        container
                        key={item.label}
                        align="center"
                        onClick={() => state.updateIndex(i)}
                        id={`feature-select-${i}`}
                        className={classnames(
                            styles.feature,
                            i === selected && styles.active
                        )}
                    >
                        {item.icon}
                        <p className={styles['description-desktop']}>
                            {item.label}
                        </p>
                    </Flex>
                ))}
            </Flex>
        </div>
    )
}
FeatureSelect.propTypes = {
    className: PropTypes.string,
}
FeatureSelect.defaultProps = {
    className: '',
}
export default FeatureSelect
