import React from 'react'
import PropTypes from 'prop-types'
import Svg from './'

const BillIcon = props => {
    const { className, ...other } = props

    return (
        <Svg
            width="164px"
            height="164px"
            viewBox="0 0 164 164"
            className={className}
            {...other}
        >
            <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="BillIcon" stroke="#011E41" strokeWidth="4">
                    <g id="bill" transform="translate(50.000000, 34.000000)">
                        <rect
                            id="Rectangle"
                            x="0"
                            y="0"
                            width="64"
                            height="96"
                        />
                        <path d="M8,32 L56,32" id="Path" />
                        <path d="M8,8 L28,8" id="Path" />
                        <path d="M48,8 L56,8" id="Path" />
                        <path d="M8,44 L56,44" id="Path" />
                        <path d="M8,56 L56,56" id="Path" />
                    </g>
                </g>
            </g>
        </Svg>
    )
}
BillIcon.propTypes = {
    className: PropTypes.string,
}
BillIcon.defaultProps = {
    className: '',
}
export default BillIcon
