import React, { useState, useEffect } from 'react'
import Flex from '../Flex';
import styles from './style.module.css';
import CalculatorRangeSlider from './CalculatorRangeSlider';


const Calculator = (props) => {
    const [ sizeSliderValue, setSizeSliderValue ] = useState(50);
    const [ cleaningSliderValue, setCleaningSliderValue ] = useState(1);

    const [minPrice] = useState(79);
    const [maxPrice] = useState(269);
    const [effectiveYta] = useState(0.65);
    const [vectorPerManad] = useState(1);
    const [kvmPerKit] = useState(4000);

    const calculator = (sizeSliderValue, cleaningSliderValue) => {
        const initialCalculatedPrice = (sizeSliderValue * cleaningSliderValue * vectorPerManad * effectiveYta);
        let initialPrice = 0;
        let initialPricePerM2 = 0;
        let initialTotalClean = 0;
        let initialMonth = 0;

        if (initialCalculatedPrice > maxPrice) {
            initialPrice = maxPrice;
            initialPricePerM2 = Math.round(maxPrice / (sizeSliderValue * cleaningSliderValue * vectorPerManad) * 100);
        } else if (initialCalculatedPrice < minPrice) {
            initialPrice = minPrice;
            initialPricePerM2 = Math.round(minPrice / (sizeSliderValue * cleaningSliderValue * vectorPerManad) * 100);
        } else {
            initialPrice = Math.round(sizeSliderValue * cleaningSliderValue * vectorPerManad * effectiveYta);
            initialPricePerM2 = 100;
        }

        initialTotalClean = Math.round(sizeSliderValue * cleaningSliderValue * vectorPerManad * effectiveYta);
        initialMonth = Math.round(kvmPerKit / (sizeSliderValue * cleaningSliderValue * vectorPerManad));

        return {
            calculatedPrice: initialPrice,
            calculatedPricePerM2: initialPricePerM2,
            calculatedTotalClean: initialTotalClean,
            calculatedMonth: initialMonth
        }
    };

    const { calculatedPrice, calculatedPricePerM2, calculatedTotalClean, calculatedMonth } = calculator(sizeSliderValue, cleaningSliderValue);


    const [ pricePerM2, setPricePerM2 ] = useState(calculatedPricePerM2);
    const [ price, setPrice ] = useState(calculatedPrice);
    const [ totalClean, setTotalClean ] = useState(calculatedTotalClean);
    const [ month, setMonth ] = useState(calculatedMonth);

    useEffect(() => {
        const { calculatedPrice, calculatedPricePerM2, calculatedTotalClean, calculatedMonth } = calculator(sizeSliderValue, cleaningSliderValue);

        setPrice(calculatedPrice);
        setPricePerM2(calculatedPricePerM2);
        setTotalClean(calculatedTotalClean);
        setMonth(calculatedMonth);
    }, [sizeSliderValue, cleaningSliderValue]);

    return (
        <Flex
            container
            direction="column"
            className={styles['calculator-container-row']}
            align="center">
            <div className={styles['calculator-block']} id="calculate-block">
                <div className={styles['checkout-block']}>
                    <h3 className={styles['checkout-title']}>Räkna ut din kostnad</h3>
                </div>
                <div className={styles['calculator']}>
                    <CalculatorRangeSlider
                        leftLabel="Bostadens storlek"
                        sliderValue={sizeSliderValue}
                        min={10}
                        max={200}
                        step={1}
                        hasUnit={true}
                        onSliderChange={(changedSliderValue) => {
                            setSizeSliderValue(changedSliderValue)
                        }}/>
                    <CalculatorRangeSlider
                        leftLabel="Städningar per månad"
                        sliderValue={cleaningSliderValue}
                        min={1}
                        max={20}
                        step={1}
                        hasUnit={false}
                        onSliderChange={(changedSliderValue) => {
                            setCleaningSliderValue(changedSliderValue)
                        }}/>
                </div>
            </div>
            <Flex
                container
                className={styles['calculator-result-container']}
                direction="column"
                align="center">
                <div className={styles['calculator-result-block']}>
                    <h3 className={styles['calculator-result-header']}>Resultat per månad</h3>
                    <div className={styles['calculator-result-row']}>
                        <div className={styles['calculator-colm-left']}>
                            <p className={styles['calculator-colm-left-text']}>Städad golvyta</p>
                        </div>
                        <div className={styles['calculator-colm-right']}>
                        <p className={styles['calculator-colm-right-text']}>{totalClean} <span className={styles['calculator-colm-right-text-sub']}>m<sup>2</sup></span></p></div>
                    </div>
                    <div className={styles['calculator-result-row']}>
                        <div className={styles['calculator-colm-left']}>
                            <p className={styles['calculator-colm-left-text']}>Kostnad per städad m<sup>2</sup></p>
                        </div>
                        <div className={styles['calculator-colm-right']}><p className={styles['calculator-colm-right-text']}>{pricePerM2} <span className={styles['calculator-colm-right-text-sub']}>öre</span></p></div>
                    </div>
                    <div className={styles['calculator-result-row']}>
                        <div className={styles['calculator-colm-left']}>
                            <p className={styles['calculator-colm-left-text']}>Pris</p>
                        </div>
                        <div className={styles['calculator-colm-right']}><p className={styles['calculator-colm-right-text']}>{price} <span className={styles['calculator-colm-right-text-sub']}>kr</span></p></div>
                    </div>
                    <p className={styles['calculator-footer-text']}>Cirka var <strong>{month}</strong>:e månad kommer du få ett <strong>kostnadsfritt</strong> service-kit med nya borstar och filter till ett värde av 695 kr.</p>
                   
                    <div className={styles['calculator-colm-left']}>
                            <p className={styles['calculator-footer-text']}><strong>*Hur vi räknat: </strong> Städbar yta = 65% av bostadens storlek.</p>
                        </div>
                </div>
            </Flex>
        </Flex>
    )
};

export default Calculator;
