import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.css'

const Spacing = props => {
    const { children, top, right, bottom, left } = props

    return React.Children.map(children, child =>
        React.cloneElement(child, {
            className: classnames(
                top && styles[`top-${top}`],
                left && styles[`left-${left}`],
                right && styles[`right-${right}`],
                bottom && styles[`bottom-${bottom}`],
                child.props.className
            )
        })
    )
}
Spacing.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
}
Spacing.defaultProps = {
    className: ''
}
export default Spacing
