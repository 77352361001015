import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.css'
import IntersectionObserver from '../IntersectionObserver'

const AnimatedRobot = props => {
    const { className, device, animate, ...other } = props
    return (
        <IntersectionObserver threshold={0.2}>
            {({ inView, ref }) => (
                <div
                    ref={ref}
                    className={classnames(styles.root, className)}
                    {...other}
                >
                    <img
                        src="brushes.png"
                        className={classnames(
                            styles.brushes,
                            inView && styles.animate
                        )}
                        alt=""
                    />
                    <img
                        src={`purei9_sticky_${device}.png`}
                        alt="purei9"
                        className={styles['purei9']}
                    />
                </div>
            )}
        </IntersectionObserver>
    )
}
AnimatedRobot.propTypes = {
    className: PropTypes.string
}
AnimatedRobot.defaultProps = {
    className: ''
}
export default AnimatedRobot
