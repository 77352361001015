import React from 'react'
import PropTypes from 'prop-types'
import Svg from './'

const BoxIcon = React.forwardRef((props, ref) => {
    const { className, ...other } = props

    return (
        <Svg
            ref={ref}
            preserveAspectRatio="none"
            viewBox="0 0 3 202"
            className={className}
            {...other}
        >
            <g
                id="Page-1"
                stroke="none"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
            >
                <g id="ProgressBarMobile" stroke="#011E41">
                    <path
                        d="M-1.90324272e-07,1 L2.25912292,1 C2.25912292,1 2.25912292,166.970764 2.25912292,201 L-1.90324272e-07,201"
                        strokeDasharray={0}
                        id="Empty"
                        vectorEffect="non-scaling-stroke"
                        strokeOpacity="0.2"
                    />
                    <path
                        d="M-1.90324272e-07,1 L2.25912292,1 C2.25912292,1 2.25912292,166.970764 2.25912292,201 L-1.90324272e-07,201"
                        id="Filled"
                        vectorEffect="non-scaling-stroke"
                    />
                </g>
            </g>
        </Svg>
    )
})
BoxIcon.propTypes = {
    className: PropTypes.string,
}
BoxIcon.defaultProps = {
    className: '',
}
export default BoxIcon
