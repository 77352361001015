import React from 'react'
import PropTypes from 'prop-types'
import Svg from './'

const PhoneIcon = props => {
    const { className, ...other } = props

    return (
        <Svg
            width="54px"
            height="93px"
            viewBox="0 0 54 93"
            className={className}
            {...other}
        >
            <title>9CCF9F2D-C503-4EF0-8ACB-37F3F0886FAD</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="New-icons" transform="translate(-727.000000, -757.000000)" fill="#001E41" fill-rule="nonzero">
                    <g id="Iconer2-03" transform="translate(727.000000, 757.000000)">
                        <path d="M47.0864459,93 L6.94212253,93 C3.10939991,92.9968731 0.00315061616,89.9139733 0,86.110061 L0,6.88993902 C0.00315061616,3.08602674 3.10939991,0.00312693317 6.94212253,0 L47.0864459,0 C50.9080071,0.0187136285 53.9969037,3.09705928 54,6.88993902 L54,86.110061 C53.9969037,89.9029407 50.9080071,92.9812864 47.0864459,93 L47.0864459,93 Z M6.94212253,2.36469512 C4.41765938,2.36469512 2.37117765,4.39579357 2.37117765,6.90128049 L2.37117765,86.1214024 C2.37117765,88.6268894 4.41765938,90.6579878 6.94212253,90.6579878 L47.0864459,90.6579878 C49.610909,90.6579878 51.6573908,88.6268894 51.6573908,86.1214024 L51.6573908,6.88993902 C51.6573908,4.38445211 49.610909,2.35335366 47.0864459,2.35335366 L6.94212253,2.36469512 Z" id="Shape"></path>
                        <path d="M38.1216802,8.67054878 L15.4840758,8.67054878 C14.858099,8.62749726 14.3724687,8.11096243 14.3724687,7.48820122 C14.3724687,6.86544001 14.858099,6.34890518 15.4840758,6.30585366 L38.1216802,6.30585366 C38.747657,6.34890518 39.2332873,6.86544001 39.2332873,7.48820122 C39.2332873,8.11096243 38.747657,8.62749726 38.1216802,8.67054878 Z" id="Path"></path>
                        <path d="M27.0028568,88.2706098 C24.371104,88.2706098 22.2376468,86.1531896 22.2376468,83.5412195 C22.2376468,80.9292494 24.371104,78.8118293 27.0028568,78.8118293 C29.6346097,78.8118293 31.7680669,80.9292494 31.7680669,83.5412195 C31.7649185,86.1518942 29.6333045,88.2674851 27.0028568,88.2706098 Z M27.0028568,81.176497 C26.0381567,81.1719323 25.1658714,81.7451511 24.7934862,82.6284036 C24.4211009,83.5116562 24.6221162,84.5306083 25.3026283,85.20926 C25.9831404,85.8879117 27.0088317,86.0923124 27.9005539,85.726977 C28.7922761,85.3616416 29.3740345,84.4986791 29.3740345,83.5412195 C29.3740345,82.2396508 28.3142684,81.1827669 27.0028568,81.176497 L27.0028568,81.176497 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </Svg>
    )
}
PhoneIcon.propTypes = {
    className: PropTypes.string,
}
PhoneIcon.defaultProps = {
    className: '',
}
export default PhoneIcon
