import { compose, mapProps } from 'recompose'
import classnames from 'classnames'

import styles from './styles.module.css'

export default compose(
    mapProps(({ className, ...rest }) => ({
        className: classnames(styles.root, className),
        ...rest
    }))
)('div')
