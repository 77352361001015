import React from 'react'

import Cookie from '../Cookie'

import styles from './styles.module.css'

export default ({ onClickAccept, onClickDeny }) => (
    <div className={styles.root}>
        <div className={styles.container}>
            <div>
                
                Vi använder cookies och andra tekniker för att optimera vår webbplats samt för reklam- och marknadsföringssyften. 
                Vi delar också uppgifter om din användning av vår webbplats med våra partner inom sociala medier, annonsering och analys. 
                Genom att klicka och acceptera så godkänner du användningen av cookies. Du kan läsa mer och ändra dina inställningar <blank> </blank>
                <a
                target="_blank"
                className={styles.link}
                href="Cookie.html"
            >
                  Cookiemeddelande.
            </a>
        
            </div>
            <div className={styles.accept} onClick={onClickAccept}>
                <Cookie className={styles.icon} />
                <div>Godkänn</div>
            </div>
            <div className={styles.deny} onClick={onClickDeny}>
                <div>Neka</div>
            </div>
        </div>
    </div>
)
