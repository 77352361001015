import {
    compose,
    fromRenderProps,
    onlyUpdateForKeys,
    nest,
    withStateHandlers,
} from 'recompose'
import store from 'store'

import Device from '../Device'
import { ReceiptModalProvider } from '../ReceiptModal/context'
import { CheckoutModalProvider } from '../CheckoutModal/context'
import { InformationModalProvider } from '../InformationModal/context'
import { BrowserRouter as Router } from 'react-router-dom'

import Site from './Site'

export default compose(
    fromRenderProps(Device, ({ height, device }) => ({
        height,
        device,
    })),
    onlyUpdateForKeys(['height', 'device']),
    withStateHandlers(
        {
            showCookiebar: !store.get('hideCookiebar'),
            videoIndex: 0,
            sectionInView: 0,
        },
        {
            onCookiesAccept: () => () => {
                document.cookie = 'GDPRCookieConsent=TRUE'

                return {
                    showCookiebar: !store.set('hideCookiebar', true),
                }
            },
            onCookiesDeny: () => () => {
                document.cookie = 'GDPRCookieConsent=FALSE'

                return {
                    showCookiebar: !store.set('hideCookiebar', true),
                }
            },
            onUpdateVideoIndex: state => index => {
                return {
                    videoIndex: index,
                }
            },
            onSectionInView: state => index => {
                return {
                    sectionInView: index,
                }
            },
        }
    )
)(
    nest(
        Router,
        ReceiptModalProvider,
        CheckoutModalProvider,
        InformationModalProvider,
        Site
    )
)
