import {
    compose,
    defaultProps,
    fromRenderProps,
    lifecycle,
    withHandlers
} from 'recompose'

import { pushTransaction } from '../../utilities/analytics'

import { ReceiptModalConsumer } from '../ReceiptModal/context'

import Checkout from './Checkout'

export default compose(
    defaultProps({
        id: 'checkout'
    }),
    fromRenderProps(ReceiptModalConsumer, ({ open }) => ({
        openModal: open
    })),
    lifecycle({
        async componentDidMount() {

            var CONFIG_RES = await fetch('./storeapiconfig.json');
            const { Authorization, url, merchant_confirmation_url } = await CONFIG_RES.json()

            const res = await fetch(
                `${url}/store/v1/sessions`,
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": Authorization
                    },
                    body: JSON.stringify({
                        merchant_confirmation_url: merchant_confirmation_url
                    })
                }
            )

            const { client_token, session_id } = await res.json()

            window.Klarna.Payments.init({ client_token })
            window.Klarna.Payments.load({
                container: `#${this.props.id}`,
                payment_method_category: 'pay_now'
            })

            this.setState({ session_id })
        }
    }),
    withHandlers({
        onClick: ({ session_id, openModal }) => () => {
            window.Klarna.Payments.authorize(
                {
                    payment_method_category: 'pay_now',
                    auto_finalize: true
                },
                {},
                async ({ approved, authorization_token, ...rest }) => {
                    if (!approved) {
                        return window.alert(
                            'Betalningen gick ej igenom! Försök igen'
                        )
                    }

                    var CONFIG_RES = await fetch('./storeapiconfig.json');
                    const { Authorization, url, merchant_confirmation_url } = await CONFIG_RES.json()

                    const res = await fetch(
                        `${url}/store/v1/subscriptions`,
                        {
                            method: "POST",
                            mode: "cors",
                            cache: "no-cache",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": Authorization
                            },
                            body: JSON.stringify({
                                session_id: session_id,
                                authorization_token: authorization_token,
                                contract_id: 3,
                                merchant_confirmation_url: merchant_confirmation_url
                            })
                        }
                    )
                    const { redirect_url } = await res.json()

                    if (!redirect_url) {
                        return window.alert('Ett fel inträffade! Försök igen')
                    }

                    pushTransaction(session_id)
                    openModal()
                }
            )
        }
    })
)(Checkout)
