import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.css'

const ProgressBar = props => {
    const { className, progress, ...other } = props

    return (
        <div className={classnames(styles.root, className)} {...other}>
            <div
                className={styles.progress}
                style={{ transform: `scaleX(${progress})` }}
            />
        </div>
    )
}
ProgressBar.propTypes = {
    className: PropTypes.string,
}
ProgressBar.defaultProps = {
    className: '',
}
export default ProgressBar
