import React from 'react'
import classnames from 'classnames'

import Cancel from '../Cancel'
import Header from '../Header'

import styles from './styles.module.css'

export default ({
    children,
    isOpen,
    header,

    onRef,
    close,
}) => (
    <div
        ref={onRef}
        className={classnames(styles.root, {
            [styles.open]: isOpen,
        })}
    >
        {children}

        {header && (
            <Header
                sticky
                visible
                onClick={close}
            />
        )}

        {/* <div
            className={styles.close}
            onClick={close}
        >
            <Cancel />
        </div> */}
    </div>
)
